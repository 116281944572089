import { ColorTheme, SpeakerPreview } from '@types'
import NextLink from 'next/link'
import { rgba } from 'polished'
import React, { useState } from 'react'
import { Box, Flex } from 'theme-ui'
import { COLORS } from '../../constants'
import Button from '../Button'
import ImageAvatar from '../ImageAvatar'
import Link from '../Link'

export type Props = {
  colorTheme?: ColorTheme
  speaker: SpeakerPreview
}

const EXCERPT_LENGTH = 450

const SpeakerProfile = (props: Props) => {
  const { colorTheme, speaker } = props

  // State
  const [hover, setHover] = useState(false)

  const color = COLORS[colorTheme?.text || 'midnight']

  const excerptTruncated =
    speaker?.excerpt && speaker.excerpt?.length > EXCERPT_LENGTH
      ? speaker.excerpt.slice(0, EXCERPT_LENGTH) + '...'
      : speaker?.excerpt

  return (
    <Box color={colorTheme?.text}>
      <Flex
        sx={{
          alignItems: 'center',
          flexDirection: 'row',
          justifyContent: 'center',
          position: 'relative',
          width: '100%',
        }}
      >
        {/* Image */}
        <NextLink href={speaker.slug}>
          <a>
            <Box
              onMouseEnter={() => setHover(true)}
              onMouseLeave={() => setHover(false)}
              sx={{
                height: ['50px', null, null, '136px'],
                width: ['50px', null, null, '136px'],
              }}
            >
              <ImageAvatar image={speaker.image} sizes="136px" zoomed={hover} />
            </Box>
          </a>
        </NextLink>

        <Box ml={[3, null, null, 8]} sx={{ flex: 1 }}>
          {/* Name */}
          {speaker?.name && (
            <Box>
              <NextLink href={speaker.slug} passHref>
                <Link
                  active={hover}
                  onMouseEnter={() => setHover(true)}
                  onMouseLeave={() => setHover(false)}
                  sx={{
                    fontSize: ['xs', null, null, 'm'],
                    fontWeight: 'semibold',
                  }}
                  variant="simple"
                >
                  {speaker.name}
                </Link>
              </NextLink>
            </Box>
          )}

          {/* Role */}
          {speaker?.role && (
            <Box
              color={rgba(color, 0.5)}
              mt={[1, null, null, 1]}
              sx={{
                fontSize: ['xxs', null, null, 's'],
                lineHeight: 'body',
              }}
            >
              {speaker.role}
            </Box>
          )}
        </Box>
      </Flex>

      {/* Excerpt */}
      {excerptTruncated && (
        <Box
          mt={[4, null, null, 5]}
          sx={{
            fontSize: ['xs', null, null, 's'],
            lineHeight: 'body',
            maxWidth: ['480px', null, null, 'none'],
            whiteSpace: 'pre-line',
          }}
        >
          {excerptTruncated}
        </Box>
      )}

      {/* Link */}
      {speaker?.slug && (
        <Box mt={5}>
          <NextLink href={speaker.slug}>
            <a>
              <Button
                background={colorTheme?.text}
                color={colorTheme?.background}
                variant="outline"
              >
                See full profile
              </Button>
            </a>
          </NextLink>
        </Box>
      )}
    </Box>
  )
}

export default SpeakerProfile
