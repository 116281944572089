import { ColorTheme } from '@types'
import React from 'react'
import Banner from '../Banner'
import ModuleWrapper from '../ModuleWrapper'

type ModuleBanner = {
  _type: 'moduleBanner'
  banner: Banner
}

export type Props = {
  collapseMargins?: boolean
  colorTheme?: ColorTheme
  module: ModuleBanner
}

const ModuleBanner = (props: Props) => {
  const { collapseMargins, colorTheme, module } = props

  if (!module?.banner) {
    return null
  }

  return (
    <ModuleWrapper collapseMargins={collapseMargins}>
      <Banner banner={module.banner} colorTheme={colorTheme} />
    </ModuleWrapper>
  )
}

export default ModuleBanner
