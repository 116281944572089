import { PodcastPreview } from '@types'
import React from 'react'
import { Box, Flex } from 'theme-ui'
import Artboard from '../Artboard'
import BoxResponsive from '../BoxResponsive'
import Buttons from '../Buttons'
import NextImage from '../NextImage'

type Props = {
  podcast: PodcastPreview
}

const PodcastCover = (props: Props) => {
  const { podcast } = props

  const colorTheme = podcast?.artboard?.colorTheme

  return (
    <BoxResponsive
      aspect={[343 / 428, null, null, null, 647 / 478]}
      bg={podcast?.artboard ? undefined : 'lightGray'}
      sx={{ borderRadius: 1, overflow: 'hidden' }}
    >
      {/* Artboard */}
      {podcast?.artboard && <Artboard artboard={podcast.artboard} />}

      {/* Image */}
      <Flex
        sx={{
          alignItems: 'center',
          flex: 1,
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center',
          position: 'relative',
        }}
      >
        {podcast?.image && (
          <Box
            sx={{
              borderRadius: 1,
              flexShrink: 0,
              height: '45%',
              overflow: 'hidden',
              position: 'relative',
              width: '45%',
            }}
          >
            <NextImage
              image={podcast.image}
              layout="fill"
              objectFit="contain"
              sizes="20vw"
            />
          </Box>
        )}

        <Flex
          color={colorTheme?.text}
          mt={[4, null, null, null, 6]}
          px={4}
          sx={{
            alignItems: 'center',
            flexDirection: 'column',
            justifyContent: 'center',
            width: '100%',
          }}
        >
          {/* Title */}
          {podcast?.title && (
            <Box
              sx={{
                fontSize: ['l', null, null, null, 'xl'],
                fontWeight: 'semibold',
                overflow: 'hidden',
                textAlign: 'center',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              {podcast.title}
            </Box>
          )}
          {/* Description */}
          {podcast?.description && (
            <Box
              mt={4}
              sx={{
                lineHeight: 'body',
                maxWidth: ['280px', null, null, null, '420px'],
                textAlign: 'center',
              }}
            >
              {podcast.description}
            </Box>
          )}
        </Flex>

        {/* Links */}
        <Buttons colorTheme={colorTheme} links={podcast.links} mt={4} />
      </Flex>
    </BoxResponsive>
  )
}

export default PodcastCover
