import { ColorTheme, Testimonial } from '@types'
import React from 'react'
import ModuleWrapper from '../ModuleWrapper'
import Testimonials from '../Testimonials'

type ModuleTestimonials = {
  _type: 'moduleTestimonials'
  testimonials: Testimonial[]
}

export type Props = {
  collapseMargins?: boolean
  colorTheme?: ColorTheme
  module: ModuleTestimonials
}

const ModuleTestimonials = (props: Props) => {
  const { collapseMargins, colorTheme, module } = props

  return (
    <ModuleWrapper collapseMargins={collapseMargins} fullWidth={true}>
      {module?.testimonials && (
        <Testimonials
          colorTheme={colorTheme}
          testimonials={module.testimonials}
        />
      )}
    </ModuleWrapper>
  )
}

export default ModuleTestimonials
