import {
  ArticlePreview,
  ColorTheme,
  ManifestoPreview,
  OnlineCoursePreview,
  ProductPreview,
  TagTone,
  TalkPreview,
  WorkshopPreview,
} from '@types'
import { Box } from 'theme-ui'
import isCourseGuard from '../../utils/isCourseGuard'
import isEventOrWorkshopGuard from '../../utils/isEventOrWorkshopGuard'
import isOnlineCourseGuard from '../../utils/isOnlineCourseGuard'
import Tag from '../Tag'

type Props = {
  colorTheme?: ColorTheme
  item:
    | ArticlePreview
    | ManifestoPreview
    | OnlineCoursePreview
    | ProductPreview
    | TalkPreview
    | WorkshopPreview
  tone?: TagTone
}

const ReferencesSecondary = (props: Props) => {
  const { colorTheme, item, tone = 'dark' } = props

  let points: string[] = []

  // Online course
  if (isOnlineCourseGuard(item)) {
    if (item?.onlineCourseType) {
      points.push(item.onlineCourseType.title)
    }
  }

  // Online course / workshop / event
  if (isCourseGuard(item)) {
    // Duration
    if (item?.duration) {
      points.push(item.duration)
    }

    // Location/s
    if (isEventOrWorkshopGuard(item) && item?.location) {
      points.push(item.location)
    }

    // Date
    if (item?.date) {
      points.push(item.date)
    }
  }

  return (
    <Box>
      {points.map((val, index) => (
        <Tag
          colorTheme={colorTheme}
          label={val}
          key={index}
          mr="2px"
          mt="2px"
          tone={tone}
        />
      ))}
    </Box>
  )
}

export default ReferencesSecondary
