import { Vimeo } from '@types'
import Plyr from 'plyr'
import React, { useEffect, useRef } from 'react'
import ReactDOM from 'react-dom'
import { AspectRatio, Box } from 'theme-ui'
import useTypedSelector from '../../hooks/useTypedSelector'
import { selectMeasurementConsent } from '../../redux/modules/iubenda'
import { trackTalkPlay } from '../../utils/analytics/trackTalkPlay'
import { trackVideoPlay } from '../../utils/analytics/trackVideoPlay'
import IubendaMeasurementNotice from '../IubendaMeasurementNotice'

export type Props = {
  autoplay?: boolean
  isTalk?: boolean
  onPlayNext?: () => void
  onVideoEnded?: () => void
  shuffle?: any
  title?: string
  vimeo: Vimeo
}

const NextButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <div onClick={onClick}>
      <svg role="presentation">
        {/* <use xlinkHref="#plyr-fast-forward"></use> */}
        <use xlinkHref="/plyr/sprites.svg#plyr-fast-forward"></use>
      </svg>
      <span className="plyr__tooltip">Next video</span>
    </div>
  )
}

const PlyrVideo = (props: Props) => {
  const {
    autoplay = false,
    isTalk,
    onPlayNext,
    onVideoEnded,
    shuffle,
    vimeo,
  } = props

  // Redux
  const measurementConsent = useTypedSelector(selectMeasurementConsent)

  // Refs
  const refMounted = useRef(false)
  const refContainer = useRef<HTMLDivElement | null>(null)
  const refNextButtonContainer = useRef<HTMLDivElement | null>(null)
  const refPlayer = useRef<Plyr | null>(null)
  const refPreviousButtonContainer = useRef<HTMLDivElement | null>(null)

  // Callbacks
  const handleExitFullscreen = () => {
    if (!refPlayer.current) {
      return
    }

    if (refPlayer.current.fullscreen.active) {
      refPlayer.current.fullscreen.exit()
    }
  }

  const handleVideoDestroy = () => {
    if (refPlayer.current) {
      refPlayer.current.destroy()
    }
  }

  const handleVideoEnded = () => {
    // HACK: exit fullscreen before navigating to prevent uncaught plyr error
    handleExitFullscreen()
    if (onVideoEnded) {
      onVideoEnded()
    }
  }

  const handleVideoPlay = () => {
    if (!vimeo?.source?.name) {
      return
    }

    if (isTalk) {
      trackTalkPlay(vimeo.source.name)
    } else {
      trackVideoPlay(vimeo.source.name)
    }
  }

  const handleVideoReady = () => {
    if (!refPlayer.current) {
      return
    }

    // Listen to Vimeo ended event
    refPlayer.current.embed.on('ended', handleVideoEnded)

    // Add next / previous buttons
    injectButtons()
  }

  const injectButtons = () => {
    if (!refPlayer.current) {
      return
    }

    if (onPlayNext) {
      const parentEl = document.querySelector('.plyr__controls')
      const timeEl = document.querySelector('.plyr__controls__item.plyr__time')

      if (parentEl && timeEl) {
        // Find existing button
        refNextButtonContainer.current = document.querySelector(
          '.plyr__control[data-plyr="next"]'
        )

        if (!refNextButtonContainer.current) {
          // Inject next button (before time) if it doesn't already exist
          const nextTargetEl = document.createElement('button')
          nextTargetEl.className = 'plyr__control'
          nextTargetEl.setAttribute('type', 'button')
          nextTargetEl.dataset.plyr = 'next'
          refNextButtonContainer.current = timeEl.insertAdjacentElement(
            'beforebegin',
            nextTargetEl
          ) as HTMLDivElement
        }

        // Render in place
        ReactDOM.render(
          <NextButton
            onClick={() => {
              // HACK: exit fullscreen before navigating to prevent uncaught plyr error
              handleExitFullscreen()
              onPlayNext()
            }}
          />,
          refNextButtonContainer.current
        )
      }
    }
  }

  // Effects
  // Initialize plyr
  useEffect(() => {
    if (!measurementConsent || !refContainer.current || !vimeo?.source?.id) {
      return
    }

    // Try to find existing video element
    let videoEl = refContainer.current.querySelector('video')
    if (!videoEl) {
      videoEl = document.createElement('video')
    }

    refContainer.current.appendChild(videoEl)

    refPlayer.current = new Plyr(videoEl, {
      autoplay,
      captions: {
        active: true,
      },
      controls: [
        'play-large',
        'play',
        'current-time',
        'progress',
        'mute',
        'volume',
        'captions',
        // 'settings',
        'fullscreen',
      ],
      hideControls: false,
      iconUrl: '/plyr/sprites.svg',
      tooltips: {
        controls: true,
        seek: true,
      },
      vimeo: {
        background: true,
        dnt: !measurementConsent,
        speed: true,
      },
    })

    refPlayer.current.on('play', handleVideoPlay)
    refPlayer.current.on('ready', handleVideoReady)

    refPlayer.current.source = {
      // TODO: specify if using a custom poster image
      // poster: posterImageUrl,
      sources: [{ src: vimeo.source.id, provider: 'vimeo' }],
      type: 'video',
    }

    return () => {
      handleVideoDestroy()
    }
  }, [measurementConsent])

  // Re-inject / update manually added buttons when shuffle settings have changed
  useEffect(() => {
    // Ignore initial mount
    if (!refMounted.current) {
      refMounted.current = true
      return
    }

    injectButtons()
  }, [refPlayer.current, shuffle])

  // Clean up injected components added via react-dom
  useEffect(() => {
    return () => {
      if (refNextButtonContainer.current) {
        ReactDOM.unmountComponentAtNode(refNextButtonContainer.current)
      }

      if (refPreviousButtonContainer.current) {
        ReactDOM.unmountComponentAtNode(refPreviousButtonContainer.current)
      }
    }
  }, [])

  return (
    <AspectRatio ratio={vimeo?.source?.dimensions?.aspectRatio || 16 / 9}>
      <Box
        ref={refContainer}
        sx={{
          height: '100%',
          left: 0,
          position: 'absolute',
          top: 0,
          width: '100%',
        }}
      >
        {!measurementConsent && <IubendaMeasurementNotice />}
      </Box>
    </AspectRatio>
  )
}

export default PlyrVideo
