import { ColorTheme, OnlineCoursePreview } from '@types'
import React from 'react'
import { Box } from 'theme-ui'
import CardLarge from '../CardLarge'
import IndexSubheader from '../IndexSubheader'
import ModuleWrapper from '../ModuleWrapper'

type ModuleOnlineCourse = {
  _type: 'moduleOnlineCourse'
  onlineCourse: OnlineCoursePreview
}

export type Props = {
  collapseMargins?: boolean
  colorTheme?: ColorTheme
  module: ModuleOnlineCourse
}

const ModuleOnlineCourse = (props: Props) => {
  const { collapseMargins, colorTheme, module } = props

  return (
    <ModuleWrapper collapseMargins={collapseMargins}>
      <Box color={colorTheme?.text}>
        <IndexSubheader
          illustrationType="create"
          large
          title="Featured online course"
        />
      </Box>

      {module?.onlineCourse && (
        <CardLarge colorTheme={colorTheme} item={module.onlineCourse} />
      )}
    </ModuleWrapper>
  )
}

export default ModuleOnlineCourse
