import NextLink from 'next/link'
import React, { useState } from 'react'
import { Box, Flex } from 'theme-ui'
import { LinkedLayout } from '../../types'
import Artboard from '../Artboard'
import BoxResponsive from '../BoxResponsive'
import Button from '../Button'
import NextImageZoomable from '../NextImageZoomable'

type Cover = {
  label?: string
  linkedLayout?: LinkedLayout
  slug: string
  title: string
}

type Props = {
  aspect?: (number | null)[]
  cover: Cover
  imageObjectFit?: 'cover' | 'contain'
}

const Cover = (props: Props) => {
  const {
    aspect = [343 / 339, null, null, null, 423 / 418],
    cover,
    imageObjectFit = 'cover',
  } = props

  const colorTheme = cover?.linkedLayout?.artboard?.colorTheme

  // State
  const [hover, setHover] = useState(false)

  if (!cover?.linkedLayout || !cover?.slug || !cover?.title) {
    return null
  }

  /**
   * Check the link if it's external,
   * if so, open in a new tab.
   */
  const additionalProps = /(http(s?)):\/\//i.test(cover.slug)
    ? {
        rel: 'noopener noreferrer',
        target: '_blank',
      }
    : {}

  return (
    <NextLink href={cover.slug}>
      <a {...additionalProps}>
        <Box
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
        >
          <BoxResponsive
            aspect={aspect}
            bg={cover?.linkedLayout?.artboard ? undefined : 'lightGray'}
            sx={{ borderRadius: 1, overflow: 'hidden' }}
          >
            {/* Artboard */}
            {cover?.linkedLayout?.artboard && (
              <Artboard artboard={cover.linkedLayout.artboard} />
            )}

            {/* Image */}
            <Flex
              sx={{
                alignItems: 'center',
                flex: 1,
                flexDirection: 'column',
                height: '100%',
                justifyContent: 'center',
                position: 'relative',
              }}
            >
              {cover?.linkedLayout?.image && (
                <>
                  {imageObjectFit === 'contain' && (
                    <Box
                      sx={{
                        flexShrink: 0,
                        height: '45%',
                        position: 'relative',
                        width: '45%',
                      }}
                    >
                      <NextImageZoomable
                        image={cover.linkedLayout.image}
                        layout="fill"
                        objectFit={imageObjectFit}
                        sx={{
                          img: {
                            filter:
                              'drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.25))',
                          },
                        }}
                        zoomed={hover}
                      />
                    </Box>
                  )}

                  {imageObjectFit === 'cover' && (
                    <Box
                      sx={{
                        borderRadius: 1,
                        flexShrink: 0,
                        height: '45%',
                        overflow: 'hidden',
                        position: 'relative',
                        width: '45%',
                      }}
                    >
                      <NextImageZoomable
                        image={cover.linkedLayout.image}
                        layout="fill"
                        objectFit={imageObjectFit}
                        zoomed={hover}
                      />
                    </Box>
                  )}
                </>
              )}

              {/* Title */}
              <Box
                color={colorTheme?.text}
                mb={4}
                mt={[4, null, null, null, 6]}
                px={4}
                sx={{ width: '100%' }}
              >
                {cover?.title && (
                  <Box
                    sx={{
                      fontSize: ['m', null, null, null, 'l'],
                      fontWeight: 'semibold',
                      overflow: 'hidden',
                      textAlign: 'center',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {cover.title}
                  </Box>
                )}

                {/* Subtitle */}
                {cover?.linkedLayout?.subtitle && (
                  <Box
                    mt={1}
                    sx={{
                      fontSize: ['xs', null, null, null, 's'],
                      fontWeight: 'regular',
                      overflow: 'hidden',
                      textAlign: 'center',
                      width: '100%',
                    }}
                  >
                    {cover.linkedLayout.subtitle}
                  </Box>
                )}
              </Box>

              {/* Button */}
              {cover?.slug && (
                <Button
                  active={hover}
                  background={colorTheme?.text}
                  color={colorTheme?.background}
                  variant="outline"
                >
                  {cover?.label || 'Learn more'}
                </Button>
              )}
            </Flex>
          </BoxResponsive>
        </Box>
      </a>
    </NextLink>
  )
}

export default Cover
