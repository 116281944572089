import { SanityBlock } from '@types'
import React, { ReactNode } from 'react'
import { Box } from 'theme-ui'
import AccordionListDetails from '../AccordionListDetails'
import PortableText from '../PortableText'

type Props = {
  openFirstItem?: boolean
  sections?: {
    body?: SanityBlock[]
    list?: ReactNode[]
    title: string
  }[]
}

const AccordionList = (props: Props) => {
  const { openFirstItem, sections } = props

  // TODO: consider adding spinner
  if (!sections) {
    return (
      <Box mx="auto" p={2} sx={{ textAlign: 'center' }}>
        Loading...
      </Box>
    )
  }

  return (
    <Box mx="auto">
      {sections?.map((section, index) => (
        <Box bg="white" mb={2} key={index}>
          <AccordionListDetails
            initialOpen={openFirstItem && index === 0}
            title={section.title}
          >
            {/* List */}
            {section?.list?.map((listItem, index) => (
              <Box
                key={index}
                pb={3}
                sx={{
                  fontSize: ['xs', null, null, 's'],
                  lineHeight: 'single',
                }}
              >
                {listItem}
              </Box>
            ))}

            {/* Portable text */}
            {section?.body && (
              <Box
                sx={{
                  li: {
                    fontSize: ['xs', null, null, 's'],
                  },
                  p: {
                    fontSize: ['xs', null, null, 's'],
                    lineHeight: 'body',
                  },
                }}
              >
                <PortableText blocks={section.body} />
              </Box>
            )}
          </AccordionListDetails>
        </Box>
      ))}
    </Box>
  )
}

export default AccordionList
