import React from 'react'
import { Box } from 'theme-ui'
import isCourseGuard from '../../utils/isCourseGuard'
import isReadableGuard from '../../utils/isReadableGuard'
import type Card from '../Card'
import ReferencesSecondary from '../ReferencesSecondary'
import Tag from '../Tag'
import TalkMark from '../TalkMark'
import VideoTimecode from '../VideoTimecode'

type Props = {
  item: Card
}

const CardDetails = (props: Props) => {
  const { item } = props

  // Talk: mark + timecode
  return (
    <Box>
      {/* Any 'reading' item: reading time */}
      {isReadableGuard(item) && (
        <Box
          sx={{
            bottom: '10px',
            left: '10px',
            position: 'absolute',
          }}
        >
          <Tag label={`${item?.readingTime || 1} min read`} tone="dark" />
        </Box>
      )}

      {/* Talk */}
      {item._type === 'talk' && (
        <Box>
          <Box
            sx={{
              bottom: 0,
              left: 0,
              position: 'absolute',
              width: '94px',
            }}
          >
            <TalkMark stringToHash={item.title} />
          </Box>

          <Box sx={{ bottom: 2, left: 2, position: 'absolute' }}>
            <VideoTimecode duration={item.duration} />
          </Box>
        </Box>
      )}

      {/* Online course / workshop / event */}
      {isCourseGuard(item) && (
        <Box
          color="white"
          p={2}
          sx={{
            bottom: 0,
            fontSize: 'xxs',
            left: 0,
            position: 'absolute',
            width: '100%',
          }}
        >
          <ReferencesSecondary item={item} />
        </Box>
      )}
    </Box>
  )
}

export default CardDetails
