import { ColorTheme, SanityBlock } from '@types'
import React from 'react'
import { Box } from 'theme-ui'
import ModuleWrapper from '../ModuleWrapper'
import PortableText from '../PortableText'

type ModuleText = {
  _type: 'moduleText'
  body: SanityBlock[]
  collapsed?: boolean
}

export type Props = {
  collapseMargins?: boolean
  colorTheme?: ColorTheme
  module: ModuleText
}

const ModuleText = (props: Props) => {
  const { collapseMargins, colorTheme, module } = props

  return (
    <ModuleWrapper collapseMargins={collapseMargins}>
      <Box
        color={colorTheme?.text}
        mx="auto"
        sx={{ maxWidth: 'moduleTextOneColMaxWidth' }}
      >
        {/* Body */}
        {module?.body && (
          <PortableText
            blocks={module.body}
            collapsible={module?.collapsed}
            colorTheme={colorTheme}
            variant="body"
          />
        )}
      </Box>
    </ModuleWrapper>
  )
}

export default ModuleText
