import { ModuleCarouselSlide } from '@types'
import { useEmblaCarousel } from 'embla-carousel/react'
import { useAnimation } from 'framer-motion'
import React, { useEffect, useRef, useState } from 'react'
import { Box, Flex, Heading, Paragraph } from 'theme-ui'
import useOnScreen from '../../hooks/useOnScreen'
import usePageVisibility from '../../hooks/usePageVisibility'
import BoxMotion from '../BoxMotion'
import BoxResponsive from '../BoxResponsive'
import Button from '../Button'
import LinkSanity from '../LinkSanity'
import ModuleWrapper from '../ModuleWrapper'
import NextImage from '../NextImage'
import OverlayRadialGradient from '../OverlayRadialGradient'

type ModuleCarousel = {
  _type: 'moduleCarousel'
  slides: ModuleCarouselSlide[]
}

export type Props = {
  collapseMargins?: boolean
  module: ModuleCarousel
}

const SLIDE_INTERVAL = 12000 // ms

// TODO: DRY auto slide functionality with hero carousel

const ModuleCarousel = (props: Props) => {
  const { collapseMargins, module } = props

  const multipleSlides = module.slides && module.slides.length > 1

  const [emblaRef, emblaApi] = useEmblaCarousel({
    draggable: multipleSlides,
    loop: true,
  })
  const controls = useAnimation()
  const visibility = usePageVisibility()

  // Refs
  const refContainer = useRef(null)
  const refTimeout = useRef<ReturnType<typeof window.setTimeout>>()

  // State
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [timerEnabled, setTimerEnabled] = useState(true)

  const { inViewport } = useOnScreen(refContainer)

  // Callbacks
  const handleProgressStart = () => {
    // Imperatively animate progress bar
    if (controls && timerEnabled) {
      controls.set({ scaleX: 0 })
      controls.start({
        scaleX: 1,
        transition: {
          duration: SLIDE_INTERVAL / 1000,
          ease: 'linear',
        },
      })
    }

    if (timerEnabled) {
      handleTimerClear()
      handleTimerStart()
    }
  }

  const handleProgressStop = () => {
    handleTimerClear()
    if (controls && timerEnabled) {
      controls.start({ scaleX: 0 })
    }
  }

  const handleSelect = (index: number) => {
    if (!emblaApi) {
      return
    }

    emblaApi.scrollTo(index)
  }

  const handleSelected = () => {
    if (!emblaApi) {
      return
    }

    setSelectedIndex(emblaApi.selectedScrollSnap())
    handleProgressStart()
  }

  const handleTimerStart = () => {
    refTimeout.current = setTimeout(() => {
      if (emblaApi) {
        emblaApi.scrollNext()
      }
    }, SLIDE_INTERVAL)
  }

  const handleTimerClear = () => {
    if (refTimeout.current) {
      clearTimeout(refTimeout.current)
    }
  }

  // Effects
  // Clear existing timeout on unmount
  useEffect(() => {
    return () => {
      handleTimerClear()
    }
  }, [])

  useEffect(() => {
    if (emblaApi) {
      emblaApi.on('pointerDown', handleProgressStop)
      emblaApi.on('pointerUp', handleProgressStart)
      emblaApi.on('select', handleSelected)
    }

    return () => {
      if (emblaApi) {
        emblaApi.off('pointerDown', handleProgressStop)
        emblaApi.off('pointerUp', handleProgressStart)
        emblaApi.off('select', handleSelected)
      }
    }
  }, [emblaApi, timerEnabled])

  // Manually trigger selected callback on mount (to imperatively start animation and timeouts)
  useEffect(() => {
    if (emblaApi) {
      handleSelected()
    }
  }, [emblaApi])

  // Stop / start progress animation on page visiblity changes
  useEffect(() => {
    if (!timerEnabled) {
      return
    }

    if (!visibility || !inViewport) {
      handleProgressStop()
    } else {
      handleProgressStart()
    }
  }, [inViewport, visibility])

  return (
    <ModuleWrapper collapseMargins={collapseMargins} fullWidth={true}>
      <Box className="embla">
        <Box
          className="embla__viewport"
          ref={emblaRef}
          sx={{ position: 'relative' }}
        >
          <Box className="embla__container">
            {module.slides.map((slide, index) => {
              const link = slide.link || slide.externalLink
              return (
                <Box className="embla__slide" key={index}>
                  <BoxResponsive aspect={[2 / 3, null, 1366 / 768]}>
                    {/* Image */}
                    {slide?.image && (
                      <Box
                        sx={{
                          height: '100%',
                          left: 0,
                          position: 'absolute',
                          top: 0,
                          width: '100%',
                        }}
                      >
                        <NextImage image={slide.image} layout="fill" />
                      </Box>
                    )}

                    {/* Radial gradient */}
                    <OverlayRadialGradient />

                    {/* Content */}
                    <Box
                      color="white"
                      sx={{
                        bottom: [18, null, 6],
                        left: [4, null, 6],
                        position: 'absolute',
                      }}
                    >
                      {/* Title */}
                      {slide.title && (
                        <Heading
                          sx={{
                            fontSize: ['xl', null, 'xxl'],
                            fontWeight: 'semibold',
                            maxWidth: '620px',
                          }}
                        >
                          {slide.title}
                        </Heading>
                      )}

                      {/* Body */}
                      {slide.title && (
                        <Paragraph
                          mt={[4, null, 1]}
                          sx={{
                            fontSize: ['xs', null, 's'],
                            lineHeight: 'body',
                            maxWidth: '350px',
                          }}
                        >
                          {slide.title}
                        </Paragraph>
                      )}

                      {/* Button */}
                      {link && (
                        <Box mt={4}>
                          <LinkSanity link={link}>
                            <Button
                              background="white"
                              color="midnight"
                              onMouseEnter={handleProgressStop}
                              onMouseLeave={handleProgressStart}
                            >
                              {link.title}
                            </Button>
                          </LinkSanity>
                        </Box>
                      )}
                    </Box>
                  </BoxResponsive>
                </Box>
              )
            })}
          </Box>

          {/* Progress bar */}
          <Box
            sx={{
              bottom: 0,
              height: '4px',
              left: 0,
              position: 'absolute',
              width: '100%',
            }}
          >
            {/* Background */}
            <Box
              bg="#999999" // TODO: use theme color
              sx={{
                bottom: 0,
                height: '100%',
                left: 0,
                position: 'absolute',
                width: '100%',
              }}
            />

            {/* Foreground */}
            <BoxMotion
              // framer-motion
              animate={controls}
              initial={{
                scaleX: 0,
              }}
              // theme-ui
              bg="white"
              sx={{
                bottom: 0,
                height: '100%',
                left: 0,
                position: 'absolute',
                transformOrigin: 'top left',
                width: '100%',
              }}
            />
          </Box>

          {/* Navigation dots */}
          {multipleSlides && (
            <Flex
              sx={{
                bottom: [8, null, 6],
                left: [4, null, 'auto'],
                position: 'absolute',
                right: ['auto', null, 6],
              }}
            >
              {module.slides?.map((_, index) => (
                <Box
                  key={index}
                  ml={[0, null, 2]}
                  mr={[2, null, 0]}
                  onClick={() => handleSelect(index)}
                  sx={{
                    background: selectedIndex === index ? 'white' : '#999999', // TODO: use theme color
                    borderRadius: '12px',
                    cursor: 'pointer',
                    height: '12px',
                    width: '12px',
                  }}
                />
              ))}
            </Flex>
          )}
        </Box>
      </Box>
    </ModuleWrapper>
  )
}

export default ModuleCarousel
