import { ColorTheme, Testimonial } from '@types'
import { useEmblaCarousel } from 'embla-carousel/react'
import React, { useEffect, useState } from 'react'
import { Box, Flex, Heading } from 'theme-ui'
import truncateText from '../../utils/truncateText'
import BoxMotion from '../BoxMotion'
import BoxResponsive from '../BoxResponsive'
import Character, { PATTERN_COMPONENTS } from '../Character'
import Mark from '../Mark'
import NextImage from '../NextImage'

type Props = {
  colorTheme?: ColorTheme
  testimonials: Testimonial[]
}

const numCharacters = PATTERN_COMPONENTS.length

const carouselMaskImage = (gutterWidth: number) => {
  return `linear-gradient(
    to right, 
    rgba(255, 255, 255, 0) 0px,
    rgba(255, 255, 255, 1) ${gutterWidth}px,
    rgba(255, 255, 255, 1) calc(100% - ${gutterWidth}px),
    rgba(255, 255, 255, 0) calc(100% - 0px)
  )`
}

const Testimonials = (props: Props) => {
  const { colorTheme, testimonials } = props

  const multipleSlides = testimonials && testimonials.length > 1

  const [emblaRef, emblaApi] = useEmblaCarousel({
    draggable: multipleSlides,
    loop: true,
  })

  // State
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [patternStartIndex, setPatternStartIndex] = useState<number>(-1)

  // Callbacks
  const handleSelect = (index: number) => {
    if (!emblaApi) {
      return
    }

    emblaApi.scrollTo(index)
  }

  const handleSelected = () => {
    if (!emblaApi) {
      return
    }

    setSelectedIndex(emblaApi.selectedScrollSnap())
  }

  // Effects
  // Choose a random starting character index
  useEffect(() => {
    const patternStartIndex = Math.floor(Math.random() * numCharacters)
    setPatternStartIndex(patternStartIndex)
  }, [])

  useEffect(() => {
    if (emblaApi) {
      emblaApi.on('select', handleSelected)
    }

    return () => {
      if (emblaApi) {
        emblaApi.off('select', handleSelected)
      }
    }
  }, [emblaApi])

  return (
    <Box className="embla">
      <Box
        className="embla__viewport"
        ref={emblaRef}
        sx={{
          maskImage: [carouselMaskImage(16), null, null, carouselMaskImage(24)],
          position: 'relative',
        }}
      >
        <Box className="embla__container" pb={4} sx={{ height: '100%' }}>
          {testimonials.map((testimonial, index) => (
            <Flex
              className="embla__slide"
              key={index}
              px={[4, null, 6]}
              sx={{
                alignItems: 'center',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              {/* Container */}
              <BoxResponsive
                aspect={[
                  342 / 308,
                  null,
                  1318 / 474, // 1318 / 374
                ]}
              >
                <Flex
                  bg={colorTheme?.background || 'white'}
                  color={colorTheme?.text || 'midnight'}
                  // px={[10, null, null, 40]}
                  // py={[24, null, null, 24]}
                  sx={{
                    alignItems: 'center',
                    borderRadius: 1,
                    flexDirection: 'column',
                    height: '100%',
                    justifyContent: 'center',
                    position: 'relative',
                    width: '100%',
                  }}
                >
                  {/* Bottom arrow / corner */}
                  <Box
                    bg={colorTheme?.background || 'white'}
                    sx={{
                      borderRadius: 1,
                      height: '28px',
                      position: 'absolute',
                      top: 'calc(100% - 14px - 4px)', // half height & border radius
                      transform: 'rotate(45deg)',
                      width: '28px',
                    }}
                  />

                  {/* Marks */}
                  <Box
                    sx={{
                      height: '100%',
                      left: 0,
                      overflow: 'hidden',
                      position: 'absolute',
                      top: 0,
                      width: '100%',
                    }}
                  >
                    {/* TL */}
                    <BoxMotion
                      // animate
                      animate={{
                        rotate: -40,
                        x: '-23%',
                        y: '-18%',
                      }}
                      // theme-ui
                      sx={{
                        height: ['110px', null, null, '200px'],
                        left: 0,
                        position: 'absolute',
                        top: 0,
                        width: ['110px', null, null, '200px'],
                      }}
                    >
                      <Mark color={colorTheme?.shape} patternIndex={4} />
                    </BoxMotion>

                    {/* BR */}
                    <BoxMotion
                      // animate
                      animate={{
                        rotate: -40,
                        x: '42%',
                        y: '25%',
                      }}
                      // theme-ui
                      sx={{
                        height: ['120px', null, null, '200px'],
                        right: 0,
                        position: 'absolute',
                        bottom: 0,
                        width: ['120px', null, null, '200px'],
                      }}
                    >
                      <Mark color={colorTheme?.shape} patternIndex={18} />
                    </BoxMotion>
                  </Box>

                  {/* Image */}
                  {testimonial?.image && (
                    <Box
                      mb={3}
                      sx={{
                        height: ['48px', null, null, '80px'],
                        position: 'relative',
                        width: ['48px', null, null, '80px'],
                      }}
                    >
                      <NextImage
                        image={testimonial.image}
                        layout="fill"
                        sizes="80px"
                      />
                    </Box>
                  )}

                  {/* Quote */}
                  {testimonial?.quote && (
                    <Heading
                      mb={2}
                      px={[4, null, null, 8]}
                      sx={{
                        fontSize: ['m', 'l', null, 'xl'],
                        maxWidth: ['320px', '480px', null, '770px'],
                        position: 'relative',
                        textAlign: 'center',
                      }}
                    >
                      “{truncateText(testimonial.quote.trim(), 245)}”
                    </Heading>
                  )}
                </Flex>
              </BoxResponsive>

              {/* Character */}
              {patternStartIndex >= 0 && (
                <Flex
                  mb={3}
                  mt={9}
                  sx={{
                    alignItems: 'center',
                    height: '45px',
                    justifyContent: 'center',
                    position: 'relative',
                    width: '45px',
                  }}
                >
                  <Character
                    patternIndex={(patternStartIndex + index) % numCharacters}
                  />
                </Flex>
              )}

              {/* Attribution */}
              {testimonial?.attribution && (
                <Box
                  color="slate"
                  mt={3}
                  sx={{
                    fontSize: ['xs'],
                    lineHeight: 'single',
                  }}
                >
                  {testimonial.attribution.trim()}
                </Box>
              )}
            </Flex>
          ))}
        </Box>

        {/* Carousel navigation */}
        {multipleSlides && (
          <Flex
            // bg="red"
            sx={{
              alignItems: 'center',
              justifyContent: 'center',
              // bottom: '15px',
              // position: 'absolute',
              // left: '50%',
              // transform: 'translateX(-50%)',
              zIndex: 1,
            }}
          >
            {testimonials?.map((_, index) => (
              <Box
                bg={selectedIndex === index ? 'midnight' : 'none'}
                key={index}
                mx="2px"
                onClick={() => handleSelect(index)}
                sx={{
                  border: '1px solid',
                  borderColor: 'midnight',
                  borderRadius: '8px',
                  cursor: 'pointer',
                  height: '8px',
                  width: '8px',
                }}
              />
            ))}
          </Flex>
        )}
      </Box>
    </Box>
  )
}

export default Testimonials
