import React from 'react'
import DividerHorizontalRandom from '../DividerHorizontalRandom'
import ModuleWrapper from '../ModuleWrapper'

type ModuleDivider = {
  _type: 'moduleDivider'
}

export type Props = {}

const ModuleDivider = (props: Props) => {
  return (
    <ModuleWrapper>
      <DividerHorizontalRandom sx={{ opacity: 0.2, width: '100%' }} />
    </ModuleWrapper>
  )
}

export default ModuleDivider
