import { AnimatePresence } from 'framer-motion'
import React, { ReactNode, useState } from 'react'
import { Box, Flex } from 'theme-ui'
import BoxMotion from '../BoxMotion'
import ButtonIcon from '../ButtonIcon'

type Props = {
  children: ReactNode
  initialOpen?: boolean
  title: string
}

const AccordionListDetails = (props: Props) => {
  const { children, initialOpen = false, title } = props

  // State
  const [open, setOpen] = useState(initialOpen)

  return (
    <Box
      sx={{
        border: '1px solid',
        borderColor: 'stone',
        borderRadius: 1,
        overflow: 'hidden',
      }}
    >
      {/* Header */}
      <Flex
        onClick={() => setOpen(!open)}
        pl={[5, null, null, 7]}
        pr={[3, null, null, 5]}
        py={[3, null, null, 5]}
        sx={{
          alignItems: 'center',
          cursor: 'pointer',
          justifyContent: 'space-between',
          userSelect: 'none',
        }}
      >
        {/* Title */}
        <BoxMotion
          transition={{
            damping: 35,
            stiffness: 250,
            type: 'spring',
          }}
          // theme-ui
          pr={12}
          sx={{
            fontSize: ['xs', null, null, 'm'],
            fontWeight: 'semibold',
          }}
        >
          {title}
        </BoxMotion>

        {/* Icon */}
        <BoxMotion
          // framer-motion
          animate={open ? 'open' : 'closed'}
          transition={{
            damping: 35,
            stiffness: 250,
            type: 'spring',
          }}
          variants={{
            closed: {
              rotate: 0,
            },
            open: {
              rotate: 45,
            },
          }}
          // theme-ui
          bg="stone"
          sx={{
            borderRadius: '100px',
            flexShrink: 0,
            height: '34px',
            width: '34px',
          }}
        >
          <ButtonIcon iconSize="16px" size="34px" type="add" />
        </BoxMotion>
      </Flex>

      {/* Content */}
      <Box>
        <AnimatePresence>
          {open && (
            <BoxMotion
              // framer-motion
              animate="open"
              initial="collapsed"
              exit="collapsed"
              transition={{
                damping: 35,
                stiffness: 250,
                type: 'spring',
              }}
              variants={{
                collapsed: {
                  height: 0,
                },
                open: {
                  height: 'auto',
                },
              }}
            >
              <Box
                pb={[5, null, null, 7]}
                pl={[5, null, null, 7]} //
                pr={12}
                pt={0}
              >
                {children}
              </Box>
            </BoxMotion>
          )}
        </AnimatePresence>
      </Box>
    </Box>
  )
}

export default AccordionListDetails
