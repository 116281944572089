import useSWR, { cache } from 'swr'
import { swrFetcher } from '../utils/swrFetcher'

// Fetch content only once and store in memory
const useStaleSWR = <T>(key: string) => {
  return useSWR<T>(key, swrFetcher, {
    refreshInterval: 0,
    refreshWhenHidden: false,
    refreshWhenOffline: false,
    revalidateOnFocus: false,
    revalidateOnMount: !cache.has(key),
    revalidateOnReconnect: false,
    shouldRetryOnError: false,
  })
}

export default useStaleSWR
