import { ColorTheme, SanityBlock, SanityImage } from '@types'
import React from 'react'
import { Box, Flex } from 'theme-ui'
import BoxResponsive from '../BoxResponsive'
import Caption from '../Caption'
import ModuleWrapper from '../ModuleWrapper'
import NextImage from '../NextImage'
import PortableText from '../PortableText'

type ModuleHeadingImagesAndTextTwoColumn = {
  _type: 'moduleHeadingImagesAndTextTwoColumn'
  body: SanityBlock[]
  heading: string
  imageLeft: SanityImage
  imageRight: SanityImage
}

export type Props = {
  collapseMargins?: boolean
  colorTheme?: ColorTheme
  module: ModuleHeadingImagesAndTextTwoColumn
}

const ModuleHeadingImagesAndTextTwoColumn = (props: Props) => {
  const { collapseMargins, colorTheme, module } = props

  const marginX = [0, null, null, 10]
  const marginY = [5, null, null, 0]

  return (
    <ModuleWrapper collapseMargins={collapseMargins}>
      <Box
        mx="auto"
        sx={{
          maxWidth: [
            'moduleTextOneColMaxWidth',
            null,
            null,
            'moduleTextTwoColMaxWidth',
          ],
        }}
      >
        {/* Heading */}
        {module?.heading && (
          <Box
            mb={[4, null, null, 12]}
            sx={{
              flex: 1,
              fontSize: ['l', null, null, 'xxl'],
              fontWeight: 'semibold',
              maxWidth: '1000px',
            }}
          >
            {module.heading}
          </Box>
        )}

        <Flex
          sx={{
            flexDirection: ['column', null, null, 'row'],
          }}
        >
          {/* Column 1 */}
          <Box mb={marginY} mr={marginX} sx={{ flex: 1 }}>
            {/* Image + caption */}
            {module?.imageLeft && (
              <Box>
                <BoxResponsive
                  aspect={[645 / 705]}
                  sx={{ borderRadius: 1, overflow: 'hidden' }}
                >
                  <NextImage
                    image={module.imageLeft}
                    layout="fill"
                    sizes={['100vw', null, null, '40vw']}
                  />
                </BoxResponsive>

                <Caption
                  color={colorTheme?.text}
                  text={module.imageLeft?.caption}
                />
              </Box>
            )}
          </Box>

          {/* Column 2 */}
          <Box ml={marginX} sx={{ flex: 1 }}>
            {module?.imageRight && (
              <Box mb={8}>
                {/* Image */}
                <BoxResponsive
                  aspect={[588 / 338]}
                  sx={{ borderRadius: 1, overflow: 'hidden' }}
                >
                  <NextImage
                    image={module.imageRight}
                    layout="fill"
                    sizes={['100vw', null, null, '40vw']}
                  />
                </BoxResponsive>

                {/* Caption */}
                <Caption
                  color={colorTheme?.text}
                  text={module.imageRight?.caption}
                />
              </Box>
            )}

            {/* Body */}
            {module?.body && (
              <PortableText blocks={module.body} variant="body" />
            )}
          </Box>
        </Flex>
      </Box>
    </ModuleWrapper>
  )
}

export default ModuleHeadingImagesAndTextTwoColumn
