import { Playlist } from '@types'
import React from 'react'
import { Box } from 'theme-ui'
import Card from '../Card'
import CarouselWrapper from '../CarouselWrapper'
import IndexSubheader from '../IndexSubheader'
import ModuleWrapper from '../ModuleWrapper'

type ModulePlaylistTalks = {
  _type: 'modulePlaylistTalks'
  playlist?: Playlist
}

export type Props = {
  collapseMargins?: boolean
  module: ModulePlaylistTalks
}

const ModulePlaylistTalks = (props: Props) => {
  const { collapseMargins, module } = props

  if (!module.playlist) {
    return null
  }

  return (
    <ModuleWrapper collapseMargins={collapseMargins} fullWidth>
      <Box px={[4, null, null, 6]}>
        <IndexSubheader
          description={module.playlist.description}
          href={module.playlist.slug}
          label="Watch playlist"
          large
          title={module.playlist.title}
        />
      </Box>

      {/* Carousel */}
      {module.playlist?.talks && (
        <CarouselWrapper
          columns={[1.08, 1.08, 1.08, 3]}
          nodes={module?.playlist?.talks?.map(item => (
            <Card item={item} />
          ))}
        />
      )}
    </ModuleWrapper>
  )
}

export default ModulePlaylistTalks
