import { ColorTheme, SanityBlock, SanityImage } from '@types'
import React from 'react'
import { Box, Flex } from 'theme-ui'
import BoxResponsive from '../BoxResponsive'
import Caption from '../Caption'
import ModuleWrapper from '../ModuleWrapper'
import NextImage from '../NextImage'
import PortableText from '../PortableText'

type ModuleImageAndTextTwoColumn = {
  _type: 'moduleImageAndTextTwoColumn'
  body: SanityBlock[]
  image: SanityImage
  layout: 'textLeft' | 'textRight'
}

export type Props = {
  collapseMargins?: boolean
  colorTheme?: ColorTheme
  module: ModuleImageAndTextTwoColumn
}

const ModuleImageAndTextTwoColumn = (props: Props) => {
  const { collapseMargins, colorTheme, module } = props

  const isTextLeft = module?.layout === 'textLeft'

  const marginX = [0, null, null, 10]
  const marginY = [5, null, null, 0]

  return (
    <ModuleWrapper collapseMargins={collapseMargins}>
      <Flex
        color={colorTheme?.text}
        mx="auto"
        sx={{
          flexDirection: [
            'column',
            null,
            null,
            isTextLeft ? 'row' : 'row-reverse',
          ],
          maxWidth: [
            'moduleTextOneColMaxWidth',
            null,
            null,
            'moduleTextTwoColMaxWidth',
          ],
        }}
      >
        {/* Column 1 */}
        <Box
          mb={isTextLeft ? marginY : [4, null, null, 0]}
          ml={isTextLeft ? 0 : marginX}
          mr={isTextLeft ? marginX : 0}
          sx={{ flex: 1 }}
        >
          {/* Body */}
          {module?.body && <PortableText blocks={module.body} variant="body" />}
        </Box>

        {/* Column 2 */}
        <Box
          ml={isTextLeft ? marginX : 0}
          mr={isTextLeft ? 0 : marginX}
          mt={isTextLeft ? [4, null, null, 0] : marginY}
          sx={{ flex: 1 }}
        >
          {/* Image + caption */}
          {module?.image && (
            <Box>
              <BoxResponsive
                aspect={[645 / 705]}
                sx={{
                  borderRadius: 1,
                  overflow: 'hidden',
                }}
              >
                <NextImage
                  image={module.image}
                  layout="fill"
                  sizes={['100vw', null, null, '40vw']}
                />
              </BoxResponsive>

              {/* Caption */}
              <Caption color={colorTheme?.text} text={module.image?.caption} />
            </Box>
          )}
        </Box>
      </Flex>
    </ModuleWrapper>
  )
}

export default ModuleImageAndTextTwoColumn
