import { ColorTheme, SanityImage } from '@types'
import React from 'react'
import BoxResponsive from '../BoxResponsive'
import Caption from '../Caption'
import ModuleWrapper from '../ModuleWrapper'
import NextImage from '../NextImage'

type ModuleImage = {
  _type: 'moduleImage'
  useImageAspectRatio?: boolean
  image: SanityImage
}

export type Props = {
  collapseMargins?: boolean
  colorTheme?: ColorTheme
  module: ModuleImage
}

const ModuleImage = (props: Props) => {
  const { collapseMargins, colorTheme, module } = props

  const { image, useImageAspectRatio = false } = module

  const shouldUseImageAspect =
    useImageAspectRatio &&
    typeof (image.dimensions.width / image.dimensions.height) === 'number'

  return (
    <ModuleWrapper collapseMargins={collapseMargins}>
      {/* Image */}
      <BoxResponsive
        aspect={
          !shouldUseImageAspect
            ? [1318 / 741]
            : [image.dimensions.width / image.dimensions.height]
        }
        sx={{
          borderRadius: 1,
          overflow: 'hidden',
        }}
      >
        <NextImage image={image} layout="fill" />
      </BoxResponsive>

      {/* Caption */}
      <Caption color={colorTheme?.text} text={module.image?.caption} />
    </ModuleWrapper>
  )
}

export default ModuleImage
