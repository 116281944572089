import React from 'react'
import { Flex } from 'theme-ui'
import Button from '../Button'
import LinkIubendaTrackingPreferences from '../LinkIubendaTrackingPreferences'

const IubendaMeasurementNotice = () => {
  return (
    <Flex
      bg="midnight"
      sx={{
        alignItems: 'center',
        color: 'white',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'center',
        left: 0,
        position: 'absolute',
        top: 0,
        width: '100%',
      }}
    >
      <LinkIubendaTrackingPreferences>
        <Button background="white" color="midnight" variant="outline">
          Accept measurement tracking preferences to view
        </Button>
      </LinkIubendaTrackingPreferences>
    </Flex>
  )
}

export default IubendaMeasurementNotice
