import { yupResolver } from '@hookform/resolvers/yup'
import * as Sentry from '@sentry/react'
import { formatDistanceToNowStrict } from 'date-fns'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Box, Flex } from 'theme-ui'
import * as yup from 'yup'
import Button from '../Button'
import Character, { PATTERN_COMPONENTS } from '../Character'
import Eyebrow from '../Eyebrow'
import FormFieldTextInput from '../FormFieldTextInput'
import H2 from '../H2'
import ModuleWrapper from '../ModuleWrapper'

type FormData = yup.InferType<typeof formSchema>

const formSchema = yup.object().shape({
  message: yup
    .string()
    .max(50, 'Message must be no more than 50 characters')
    .required('Please enter your message'),
})

const numCharacters = PATTERN_COMPONENTS.length

type Props = {
  module: ModuleSuggestionBox
}

type ModuleSuggestionBox = {
  _type: 'moduleSuggestionBox'
  eyebrow?: string
  messages: {
    _createdAt: string
    message: string
  }[]
  suggestionBoxId?: string
  title?: string
}

const ModuleSuggestionBox = (props: Props) => {
  const { module } = props

  // State
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [serverResponse, setFeedback] = useState<
    | {
        message: string
        status: 'error' | 'success'
      }
    | undefined
  >(undefined)
  const [patternStartIndex, setPatternStartIndex] = useState<number>(-1)

  // react-hook-form
  const {
    formState: { dirtyFields, errors },
    handleSubmit,
    register,
    reset,
  } = useForm({
    defaultValues: {
      message: '',
      phone: '',
    },
    resolver: yupResolver(formSchema),
  })

  const successMessage =
    module?.messages?.length > 0 ? (
      <>
        Thanks for your submission!
        <br />
        Here's some other responses from the community:
      </>
    ) : (
      'Thanks for your submission!'
    )

  // Callbacks
  // - submit react-hook-form
  const onSubmit = async (formData: FormData) => {
    // Disable form input
    setIsSubmitting(true)
    // Clear existing feedback
    setFeedback(undefined)

    try {
      const response = await fetch('/api/documents/suggestions/create', {
        body: JSON.stringify({
          ...formData,
          suggestionBoxId: module?.suggestionBoxId,
        }),
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
      })

      const data = await response.json()

      // Throw error if response is not 2xx
      if (!response.ok) {
        throw Error(
          data?.error?.message ||
            response?.statusText ||
            'An internal error has occurred'
        )
      }

      // Show successful feedback
      setFeedback({
        message: data?.result?.message,
        status: 'success',
      })

      // Reset react-hook-form
      reset()
    } catch (err) {
      console.error(err)

      // Sentry: capture exception
      Sentry.captureException(err)

      setFeedback({
        message: err.message,
        status: 'error',
      })
    } finally {
      // Re-allow form input
      setIsSubmitting(false)
    }
  }

  // Effects
  // Choose a random starting character index
  useEffect(() => {
    const patternStartIndex = Math.floor(Math.random() * numCharacters)
    setPatternStartIndex(patternStartIndex)
  }, [])

  if (!module.suggestionBoxId) {
    return null
  }

  return (
    <ModuleWrapper>
      <Flex
        sx={{
          alignItems: 'center',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        {/* Eyebrow */}
        {module?.eyebrow && <Eyebrow mb={5}>{module.eyebrow}</Eyebrow>}

        {/* Title */}
        {module?.title && <H2>{module.title}</H2>}

        <Box
          mt={8}
          sx={{
            maxWidth: '575px',
            width: '100%',
          }}
        >
          {/* Existing results */}
          {serverResponse?.status === 'success' && (
            <Box>
              <Box
                mb={8}
                sx={{
                  fontSize: 'm',
                  fontWeight: 'semibold',
                  textAlign: 'center',
                }}
              >
                {successMessage}
              </Box>

              {module.messages?.map((message, index) => (
                <Box
                  bg="white"
                  key={index}
                  mb={2}
                  px={8}
                  py={7}
                  sx={{
                    border: '1px solid',
                    borderColor: 'stone',
                    borderRadius: 1,
                  }}
                >
                  {/* Message */}
                  <Box sx={{ fontSize: 'm', fontWeight: 'semibold' }}>
                    “{message.message}”
                  </Box>

                  {/* Character icon + time ago */}
                  <Flex mt={4} sx={{ alignItems: 'center' }}>
                    <Box
                      sx={{
                        height: '38xpx',
                        width: '38px',
                      }}
                    >
                      <Character
                        patternIndex={
                          (patternStartIndex + index) % numCharacters
                        }
                      />
                    </Box>
                    <Box color="slate" ml={2} sx={{ fontSize: 'xxs' }}>
                      {formatDistanceToNowStrict(new Date(message._createdAt))}{' '}
                      ago
                    </Box>
                  </Flex>
                </Box>
              ))}
            </Box>
          )}

          {/* Form */}
          {serverResponse?.status !== 'success' && (
            <Flex
              as="form"
              bg="white"
              onSubmit={handleSubmit(onSubmit)}
              p={[6, null, null, 12]}
              sx={{
                alignItems: 'center',
                border: '1px solid',
                borderColor: 'stone',
                borderRadius: 1,
                flexDirection: 'column',
                justifyContent: 'center',
                minHeight: '200px',
              }}
            >
              <>
                {/* Email */}
                <FormFieldTextInput
                  disabled={isSubmitting}
                  dirty={dirtyFields?.message}
                  error={errors?.message}
                  placeholder="Enter your message"
                  sx={{ width: '100%' }}
                  variant="eyebrow"
                  {...register('message')}
                />

                {/* Phone (Honeypot) */}
                <FormFieldTextInput
                  disabled={isSubmitting}
                  dirty={dirtyFields?.phone}
                  error={errors?.phone}
                  honeypot
                  placeholder="Enter your phone number"
                  {...register('phone')}
                />

                <Button
                  background="slate"
                  disabled={isSubmitting}
                  color="white"
                  mt={8}
                  type="submit"
                  variant="outline"
                >
                  {isSubmitting ? 'Submitting' : 'Submit'}
                </Button>

                {/* Feedback (error) */}
                {serverResponse?.status === 'error' && (
                  <Box my={2} sx={{ fontSize: 'xxs', textAlign: 'center' }}>
                    {serverResponse.message}
                  </Box>
                )}
              </>
            </Flex>
          )}
        </Box>
      </Flex>
    </ModuleWrapper>
  )
}

export default ModuleSuggestionBox
function setPatternStartIndex(patternStartIndex: number) {
  throw new Error('Function not implemented.')
}
