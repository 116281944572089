import { ColorTheme, Module, SanityLinkInternal } from '@types'
import React from 'react'
import { Box } from 'theme-ui'
import getSingletonHref from '../../utils/getSingletonHref'
import Card from '../Card'
import CardLarge from '../CardLarge'
import CollectionCover from '../CollectionCover'
import Cover from '../Cover'
import ModuleBanner from '../ModuleBanner'
import ModuleCardLinks from '../ModuleCardLinks'
import ModuleCarousel from '../ModuleCarousel'
import ModuleCoverLinks from '../ModuleCoverLinks'
import ModuleDivider from '../ModuleDivider'
import ModuleFaqs from '../ModuleFaqs'
import ModuleFeatures from '../ModuleFeatures'
import ModuleHeadingImagesAndTextTwoColumn from '../ModuleHeadingImagesAndTextTwoColumn'
import ModuleImage from '../ModuleImage'
import ModuleImageAndTextTwoColumn from '../ModuleImageAndTextTwoColumn'
import ModuleImageTwoColumn from '../ModuleImageTwoColumn'
import ModuleItemWrapper from '../ModuleItemWrapper'
import ModuleItinerary from '../ModuleItinerary'
import ModuleLocations from '../ModuleLocations'
import ModuleOnlineCourse from '../ModuleOnlineCourse'
import ModulePlaylist from '../ModulePlaylist'
import ModulePlaylistTalks from '../ModulePlaylistTalks'
import ModuleQuote from '../ModuleQuote'
import ModuleSectionHeading from '../ModuleSectionHeading'
import ModuleSpeakerProfiles from '../ModuleSpeakerProfiles'
import ModuleSuggestionBox from '../ModuleSuggestionBox'
import ModuleTestimonials from '../ModuleTestimonials'
import ModuleText from '../ModuleText'
import ModuleTextTwoColumn from '../ModuleTextTwoColumn'
import ModuleThinkificCourse from '../ModuleThinkificCourse'
import ModuleVideo from '../ModuleVideo'
import ModuleWorkshop from '../ModuleWorkshop'
import PlaylistCardLarge from '../PlaylistCardLarge'
import PlaylistCover from '../PlaylistCover'
import PodcastCover from '../PodcastCover'
import SpeakerFigure from '../SpeakerFigure'
import TeamMemberFigure from '../TeamMemberFigure'

type Props = {
  collapseMargins?: boolean
  colorTheme?: ColorTheme
  isHero?: boolean
  modules: Module[]
}

const Modules = (props: Props) => {
  const { collapseMargins, colorTheme, isHero, modules } = props

  return (
    <Box mx="auto" sx={{ maxWidth: 'moduleTextTwoColMaxWidth' }}>
      {modules?.map((module, index) => {
        switch (module._type) {
          // Figures
          case 'moduleSpeakers':
          case 'moduleTeamMembers': {
            let carouselColumns
            let links

            switch (module._type) {
              case 'moduleSpeakers':
                carouselColumns = [1.08, 1.08, 2, 3, 4]
                links = [
                  {
                    _type: 'linkInternal',
                    documentType: 'speakerIndex',
                    slug: getSingletonHref('speakerIndex'),
                    title: 'View all speakers',
                  } as SanityLinkInternal,
                ]
                break
              default:
                break
            }

            return (
              <ModuleItemWrapper
                collapseMargins={collapseMargins}
                colorTheme={isHero ? colorTheme : undefined}
                fluidCarouselColumns={carouselColumns}
                fluidGridColumns={[2, 2, 2, 4]}
                heading={module.heading}
                key={index}
                links={links}
                nodes={module.items?.map(item => {
                  switch (item?._type) {
                    case 'speaker':
                      return (
                        <SpeakerFigure
                          circleIndex={index}
                          item={item}
                          key={item._id}
                        />
                      )
                    case 'teamMember':
                      return (
                        <TeamMemberFigure
                          circleIndex={index}
                          item={item}
                          key={item.name}
                        />
                      )
                    default:
                      return null
                  }
                })}
                layout={module.layout}
                subheader={{
                  title: module.title,
                }}
              />
            )
          }

          // Playlists
          case 'modulePlaylists': {
            if (module.items) {
              if (module?.items.length === 1) {
                const firstItem = module.items[0]
                return (
                  <ModuleItemWrapper
                    colorTheme={isHero ? colorTheme : undefined}
                    heading={module.heading}
                    key={index}
                    nodes={[
                      <PlaylistCardLarge
                        playlist={firstItem}
                        key={firstItem.slug}
                      />,
                    ]}
                    layout={module.layout}
                    subheader={{
                      title: module.title,
                    }}
                  />
                )
              } else {
                const carouselColumns = [1.08, 1.08, 2]
                const gridColumns = [1, 1, 1, 2]

                return (
                  <ModuleItemWrapper
                    collapseMargins={collapseMargins}
                    colorTheme={isHero ? colorTheme : undefined}
                    fluidCarouselColumns={carouselColumns}
                    fluidGridColumns={gridColumns}
                    heading={module.heading}
                    key={index}
                    nodes={module.items?.map(item => {
                      switch (item._type) {
                        case 'playlist':
                          return <PlaylistCover playlist={item} key={item.id} />
                        default:
                          return <Cover cover={item} key={item.slug} />
                      }
                    })}
                    layout={module.layout}
                    subheader={{
                      title: module.title,
                    }}
                  />
                )
              }

              return null
            }
          }

          // Covers
          case 'moduleCollections':
          case 'moduleCovers':
          case 'modulePodcasts': {
            let carouselColumns
            let gridColumns
            switch (module._type) {
              case 'moduleCollections':
                carouselColumns = [1.08, 1.08, 2]
                gridColumns = [1, 1, 1, 2]
                break
              case 'moduleCovers':
                gridColumns = [1, 2, 2, 3]
                break
              case 'modulePodcasts':
                gridColumns = [1, 1, 1, 2]
                break
              default:
                break
            }

            return (
              <ModuleItemWrapper
                collapseMargins={collapseMargins}
                colorTheme={isHero ? colorTheme : undefined}
                fluidCarouselColumns={carouselColumns}
                fluidGridColumns={gridColumns}
                heading={module.heading}
                key={index}
                nodes={module.items?.map(item => {
                  switch (item._type) {
                    case 'collection':
                      return <CollectionCover item={item} key={item.slug} />
                    case 'podcast':
                      return <PodcastCover podcast={item} key={item.title} />
                    case 'playlist':
                      return <PlaylistCover playlist={item} key={item.id} />
                    default:
                      return <Cover cover={item} key={item.slug} />
                  }
                })}
                layout={module.layout}
                subheader={{
                  title: module.title,
                }}
              />
            )
          }

          // Cards
          case 'moduleArticles':
          case 'moduleCourses':
          case 'moduleProducts':
          case 'moduleReading':
          case 'moduleTalks': {
            const carouselColumns = [1.08, 1.08, 1.08, 3]

            let nodes
            if (module.items) {
              if (module.items.length === 1) {
                const firstItem = module.items[0]
                nodes = [
                  <CardLarge
                    colorTheme={isHero ? colorTheme : undefined}
                    item={firstItem}
                    key={firstItem.slug}
                  />,
                ]
              } else {
                nodes = module.items?.map(item => {
                  return (
                    <Card
                      colorTheme={isHero ? colorTheme : undefined}
                      item={item}
                      key={item.slug}
                    />
                  )
                })
              }
            }

            return (
              <ModuleItemWrapper
                collapseMargins={collapseMargins}
                colorTheme={isHero ? colorTheme : undefined}
                fluidCarouselColumns={carouselColumns}
                heading={module.heading}
                key={index}
                nodes={nodes}
                layout={module.layout}
                subheader={{
                  title: module.title,
                }}
              />
            )
          }
          // Other
          case 'moduleBanner':
            return (
              <ModuleBanner
                collapseMargins={collapseMargins}
                colorTheme={colorTheme}
                key={index}
                module={module}
              />
            )
          case 'moduleCardLinks':
            return (
              <ModuleCardLinks
                collapseMargins={collapseMargins}
                key={index}
                module={module}
              />
            )
          case 'moduleCarousel':
            return (
              <ModuleCarousel
                collapseMargins={collapseMargins}
                key={index}
                module={module}
              />
            )
          case 'moduleCoverLinks':
            return (
              <ModuleCoverLinks
                collapseMargins={collapseMargins}
                key={index}
                module={module}
              />
            )
          case 'moduleDivider':
            return <ModuleDivider key={index} />
          case 'moduleFaqs':
            return (
              <ModuleFaqs
                collapseMargins={collapseMargins}
                key={index}
                module={module}
              />
            )
          // TODO: rename to moduleFeaturedPair
          case 'moduleFeaturedCardsPair':
            return (
              <ModuleItemWrapper
                collapseMargins={collapseMargins}
                colorTheme={isHero ? colorTheme : undefined}
                fluidGridColumns={[1, 1, 2]}
                key={index}
                nodes={module.items
                  ?.map(item => {
                    switch (item._type) {
                      case 'article':
                      case 'manifesto':
                      case 'onlineCourse':
                      case 'product':
                      case 'readingList':
                      case 'talk':
                      case 'workshop':
                        return (
                          <Card
                            colorTheme={isHero ? colorTheme : undefined}
                            item={item}
                            key={item.slug}
                          />
                        )
                      case 'playlist':
                        return <PlaylistCover playlist={item} key={item.slug} />
                      default:
                        return null
                    }
                  })
                  .slice(0, 2)}
                layout="grid"
                subheader={{
                  illustrationType: module.illustration,
                  title: module.title,
                }}
              />
            )
          case 'moduleFeatures':
            return (
              <ModuleFeatures
                collapseMargins={collapseMargins}
                colorTheme={colorTheme}
                key={index}
                module={module}
              />
            )
          case 'moduleHeadingImagesAndTextTwoColumn':
            return (
              <ModuleHeadingImagesAndTextTwoColumn
                collapseMargins={collapseMargins}
                colorTheme={isHero ? colorTheme : undefined}
                key={index}
                module={module}
              />
            )
          case 'moduleImage':
            return (
              <ModuleImage
                collapseMargins={collapseMargins}
                colorTheme={isHero ? colorTheme : undefined}
                key={index}
                module={module}
              />
            )
          case 'moduleImageTwoColumn':
            return (
              <ModuleImageTwoColumn
                collapseMargins={collapseMargins}
                key={index}
                module={module}
              />
            )
          case 'moduleImageAndTextTwoColumn':
            return (
              <ModuleImageAndTextTwoColumn
                collapseMargins={collapseMargins}
                colorTheme={isHero ? colorTheme : undefined}
                key={index}
                module={module}
              />
            )
          case 'moduleItinerary':
            return (
              <ModuleItinerary
                collapseMargins={collapseMargins}
                key={index}
                module={module}
              />
            )
          case 'moduleLocations':
            return (
              <ModuleLocations
                collapseMargins={collapseMargins}
                key={index}
                module={module}
              />
            )
          case 'moduleOnlineCourse':
            return (
              <ModuleOnlineCourse
                collapseMargins={collapseMargins}
                colorTheme={colorTheme}
                key={index}
                module={module}
              />
            )
          case 'modulePlaylist':
            return (
              <ModulePlaylist
                collapseMargins={collapseMargins}
                colorTheme={isHero ? colorTheme : undefined}
                key={index}
                module={module}
              />
            )
          case 'modulePlaylistTalks':
            return (
              <ModulePlaylistTalks
                collapseMargins={collapseMargins}
                key={index}
                module={module}
              />
            )
          case 'moduleQuote':
            return (
              <ModuleQuote
                collapseMargins={collapseMargins}
                colorTheme={isHero ? colorTheme : undefined}
                key={index}
                module={module}
              />
            )
          case 'moduleSectionHeading':
            return (
              <ModuleSectionHeading
                collapseMargins={collapseMargins}
                key={index}
                module={module}
              />
            )
          case 'moduleSpeakerProfiles':
            return (
              <ModuleSpeakerProfiles
                collapseMargins={collapseMargins}
                colorTheme={isHero ? colorTheme : undefined}
                key={index}
                module={module}
              />
            )
          case 'moduleFeaturedItems':
          case 'moduleRecentItems':
            return (
              <ModuleItemWrapper
                collapseMargins={collapseMargins}
                colorTheme={isHero ? colorTheme : undefined}
                documentIndexLink={module._type === 'moduleFeaturedItems'}
                documentType={module.documentType}
                fluidCarouselColumns={
                  module.documentType === 'playlist'
                    ? [1.08, 1.08, 1.08, 2]
                    : [1.08, 1.08, 2, 3]
                }
                fluidGridColumns={
                  module.documentType === 'playlist'
                    ? [1, 1, 1, 2]
                    : [1, 1, 2, 3]
                }
                heading={module.heading}
                key={index}
                layout={module.layout}
                links={module.links}
                nodes={module.items?.map((item, index) => {
                  switch (item._type) {
                    case 'article':
                    case 'manifesto':
                    case 'onlineCourse':
                    case 'product':
                    case 'readingList':
                    case 'talk':
                    case 'workshop':
                      return (
                        <Card
                          colorTheme={isHero ? colorTheme : undefined}
                          item={item}
                          key={item.slug}
                          sx={{
                            // Limit grid display to 3 items on smaller breakpoints
                            display:
                              module.layout === 'grid'
                                ? [index > 2 ? 'none' : 'block', null, 'block']
                                : 'block',
                          }}
                        />
                      )
                    case 'playlist':
                      return <PlaylistCover playlist={item} key={item.slug} />
                    default:
                      return null
                  }
                })}
                references={module.references}
                subheader={{
                  title: module.title,
                }}
                sx={{
                  py: 4,
                }}
              />
            )
          case 'moduleSuggestionBox':
            return <ModuleSuggestionBox key={index} module={module} />
          case 'moduleTestimonials':
            return (
              <ModuleTestimonials
                collapseMargins={collapseMargins}
                colorTheme={colorTheme}
                key={index}
                module={module}
              />
            )
          case 'moduleText':
            return (
              <ModuleText
                collapseMargins={collapseMargins}
                colorTheme={isHero ? colorTheme : undefined}
                key={index}
                module={module}
              />
            )
          case 'moduleTextTwoColumn':
            return (
              <ModuleTextTwoColumn
                collapseMargins={collapseMargins}
                colorTheme={isHero ? colorTheme : undefined}
                key={index}
                module={module}
              />
            )
          case 'moduleThinkificCourse':
            return (
              <ModuleThinkificCourse
                collapseMargins={collapseMargins}
                key={index}
                module={module}
              />
            )
          case 'moduleVideo':
            return (
              <ModuleVideo
                collapseMargins={collapseMargins}
                colorTheme={isHero ? colorTheme : undefined}
                key={index}
                module={module}
              />
            )
          case 'moduleWorkshop':
            return (
              <ModuleWorkshop
                collapseMargins={collapseMargins}
                colorTheme={colorTheme}
                key={index}
                module={module}
              />
            )
          default:
            return null
        }
      })}
    </Box>
  )
}

export default Modules
