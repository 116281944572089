import {
  ArticlePreview,
  EventPreview,
  ManifestoPreview,
  OnlineCoursePreview,
  PagePreview,
  PlaylistPreview,
  ProductPreview,
  ReadingListPreview,
  SpeakerPreview,
  TalkPreview,
  WorkshopPreview,
} from '@types'
import humanizeString from 'humanize-string'
import React, { FunctionComponent } from 'react'
import { BoxOwnProps, Flex, ThemeUIStyleObject } from 'theme-ui'
import { aliasDocumentType } from '../../utils/aliasDocumentType'

type Props = {
  item:
    | ArticlePreview
    | EventPreview
    | ManifestoPreview
    | OnlineCoursePreview
    | PagePreview
    | PlaylistPreview
    | ProductPreview
    | ReadingListPreview
    | SpeakerPreview
    | TalkPreview
    | WorkshopPreview
  sx?: ThemeUIStyleObject
}

const DocumentTypeLabel: FunctionComponent<BoxOwnProps & Props> = (
  props: Props
) => {
  const { item, sx, ...rest } = props

  let label: string = aliasDocumentType(item._type)

  return (
    <Flex
      bg="white"
      color="midnight"
      px={2}
      py={1}
      sx={{
        alignItems: 'center',
        borderRadius: '100px',
        fontSize: 'xxs',
        justifyContent: 'center',
        lineHeight: 'single',
        ...sx,
      }}
      {...rest}
    >
      {humanizeString(label)}
    </Flex>
  )
}

export default DocumentTypeLabel
