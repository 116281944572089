import React from 'react'
import { Box, Flex } from 'theme-ui'
import { ColorTheme } from '../../types'
import DividerVertical from '../DividerVertical'
import H3 from '../H3'
import ModuleWrapper from '../ModuleWrapper'

type ModuleQuote = {
  _type: 'moduleQuote'
  attribution?: string
  quote?: string
}

export type Props = {
  collapseMargins?: boolean
  colorTheme?: ColorTheme
  module: ModuleQuote
}

const ModuleQuote = (props: Props) => {
  const { collapseMargins, colorTheme, module } = props

  return (
    <ModuleWrapper collapseMargins={collapseMargins}>
      <Flex
        color={colorTheme?.text}
        mx="auto"
        sx={{
          flexDirection: 'row',
          maxWidth: 'moduleTextOneColMaxWidth',
          position: 'relative',
        }}
      >
        {/* Vertical divider */}
        <Box sx={{ height: '100%', left: 0, position: 'absolute' }}>
          <DividerVertical />
        </Box>

        <Box as="figure" ml={[8, null, null, 12]}>
          {/* Quote */}
          {module?.quote && <H3 as="blockquote">“{module.quote.trim()}”</H3>}

          {/* Attribution */}
          {module?.attribution && (
            <Box
              as="figcaption"
              mt={4}
              sx={{
                fontSize: ['xs', null, null, 's'],
                lineHeight: 'body',
              }}
            >
              {module.attribution.trim()}
            </Box>
          )}
        </Box>
      </Flex>
    </ModuleWrapper>
  )
}

export default ModuleQuote
