import { ColorTheme, IllustrationThickType, PlaylistPreview } from '@types'
import React from 'react'
import { Box } from 'theme-ui'
import IndexSubheader from '../IndexSubheader'
import ModuleWrapper from '../ModuleWrapper'
import PlaylistCardLarge from '../PlaylistCardLarge'

type ModulePlaylist = {
  _type: 'modulePlaylist'
  illustration: IllustrationThickType
  playlist?: PlaylistPreview
  title?: string
}

export type Props = {
  collapseMargins?: boolean
  colorTheme?: ColorTheme
  module: ModulePlaylist
}

const ModulePlaylist = (props: Props) => {
  const { collapseMargins, colorTheme, module } = props

  if (!module.playlist) {
    return null
  }

  return (
    <ModuleWrapper collapseMargins={collapseMargins}>
      <Box>
        <IndexSubheader
          illustrationType={module.illustration}
          large
          title={module.title}
        />
      </Box>

      <PlaylistCardLarge playlist={module.playlist} />
    </ModuleWrapper>
  )
}

export default ModulePlaylist
