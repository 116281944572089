import {
  ColorTheme,
  IllustrationThickType,
  ModuleLayout,
  Reference,
  SanityDocumentTypeIndexable,
  SanityLinkEverything,
  SanityLinkExternal,
  SanityLinkInternal,
} from '@types'
import React, { ReactNode } from 'react'
import { Box, Flex, ThemeUIStyleObject } from 'theme-ui'
import { DOCUMENT_TYPE_MAP } from '../../constants'
import { aliasDocumentType } from '../../utils/aliasDocumentType'
import getRefinementFromReference from '../../utils/getRefinementFromReference'
import getSingletonHref from '../../utils/getSingletonHref'
import Button from '../Button'
import Buttons from '../Buttons'
import CarouselWrapper from '../CarouselWrapper'
import GridWrapper from '../GridWrapper'
import IndexSubheader from '../IndexSubheader'
import ModuleHeading from '../ModuleHeading'
import ModuleWrapper from '../ModuleWrapper'
import RefinementLink from '../RefinementLink'

export type Props = {
  collapseMargins?: boolean
  colorTheme?: ColorTheme
  documentIndexLink?: boolean
  documentType?: SanityDocumentTypeIndexable
  fluidCarouselColumns?: number[]
  fluidGridColumns?: number[]
  heading?: ModuleHeading
  layout: ModuleLayout
  links?: (SanityLinkExternal | SanityLinkEverything | SanityLinkInternal)[]
  nodes?: ReactNode[]
  references?: Reference[]
  subheader?: {
    illustrationType?: IllustrationThickType
    title?: string
  }
  sx?: ThemeUIStyleObject
}

// Return an array of numbers which will always `count` if smaller
// E.g. fluidColumns([1, 2, 2, 4], 3) returns [1, 2, 2, 3]
// This is used to create responsive grid layouts that need to collapse if there are fewer items than there are columns
const fluidColumns = (cols: number[], count: number = 1) => {
  return cols.map(col => Math.min(count, col))
}

const ModuleItemWrapper = (props: Props) => {
  const {
    collapseMargins,
    colorTheme,
    documentIndexLink,
    documentType,
    fluidCarouselColumns = [1.08, 1.08, 1.08, 3],
    fluidGridColumns = [1, 1, 2, 3],
    heading,
    layout,
    links,
    nodes,
    references,
    subheader,
    sx,
  } = props

  const isCarousel = layout === 'carousel'

  let indexHref
  if (documentType && documentIndexLink) {
    const indexType = DOCUMENT_TYPE_MAP[documentType]
    indexHref = indexType && getSingletonHref(indexType)
  }

  let showSingleItem = nodes?.length === 1
  const fullWidth = isCarousel && !showSingleItem

  const carouselColumns = fluidColumns(fluidCarouselColumns, nodes?.length)
  const gridColumns = fluidColumns(fluidGridColumns, nodes?.length)

  return (
    <ModuleWrapper collapseMargins={collapseMargins} fullWidth={fullWidth}>
      <Flex
        sx={{
          alignItems: 'center',
          flexDirection: 'column',
          ...sx,
        }}
      >
        <Box
          color={colorTheme?.text}
          px={fullWidth ? [4, null, null, 6] : 0}
          sx={{ width: '100%' }}
        >
          {/* Heading */}
          <ModuleHeading heading={heading} />

          {/* References */}
          {documentType && references && references?.length > 0 && (
            <Flex
              mb={[8, null, null, 12]}
              mt={7}
              sx={{
                alignItems: 'center',
                flexWrap: 'wrap',
                justifyContent: 'center',
                maxWidth: '600px',
                mx: 'auto',
              }}
            >
              {references?.map((reference, index) => (
                <RefinementLink
                  key={index}
                  refinements={[
                    {
                      name: 'type',
                      slug: 'type',
                      value: aliasDocumentType(documentType),
                    },
                    getRefinementFromReference(reference),
                  ]}
                >
                  <a>
                    <Button
                      background="stone"
                      color="midnight"
                      mx="2px"
                      my="2px"
                    >
                      {reference.title}
                    </Button>
                  </a>
                </RefinementLink>
              ))}
            </Flex>
          )}

          {/* Subtitle */}
          {subheader && (
            <IndexSubheader
              href={indexHref}
              illustrationType={subheader?.illustrationType}
              large
              title={subheader?.title}
            />
          )}
        </Box>

        {/* Single item */}
        {showSingleItem && <Box sx={{ width: '100%' }}>{nodes}</Box>}

        {/* Multiple items: Carousel */}
        {!showSingleItem && layout === 'carousel' && (
          <CarouselWrapper columns={carouselColumns} nodes={nodes} />
        )}

        {/* Multiple items: Grid */}
        {!showSingleItem && layout === 'grid' && (
          <GridWrapper columns={gridColumns} nodes={nodes} />
        )}

        {/* Links */}
        <Buttons links={links} mt={[6, null, null, 12]} />
      </Flex>
    </ModuleWrapper>
  )
}

export default ModuleItemWrapper
