import { PlaylistPreview } from '@types'
import NextLink from 'next/link'
import pluralize from 'pluralize'
import React from 'react'
import { Box, Flex, Text } from 'theme-ui'
import Artboard from '../Artboard'
import BoxResponsive from '../BoxResponsive'
import PlaylistPlayButton from '../PlaylistPlayButton'

export type Props = {
  playlist: PlaylistPreview
}

const PlaylistCover = (props: Props) => {
  const { playlist } = props

  const playlistHref = playlist.slug

  return (
    <BoxResponsive aspect={[340 / 280, 647 / 324]}>
      <NextLink href={playlistHref} passHref>
        <a>
          <Flex
            p={5}
            sx={{
              borderRadius: '4px',
              flexDirection: 'column',
              height: '100%',
              justifyContent: 'flex-end',
              overflow: 'hidden',
              position: 'relative',
              width: '100%',
            }}
          >
            {/* Artboard */}
            {playlist?.artboard && <Artboard artboard={playlist.artboard} />}

            {/* Info */}
            <Flex
              color={playlist?.artboard?.colorTheme?.text}
              mt={1}
              sx={{ alignItems: 'center', position: 'relative' }}
            >
              <Box
                sx={{
                  alignSelf: 'flex-end',
                }}
              >
                <PlaylistPlayButton
                  colorTheme={playlist?.artboard?.colorTheme}
                  size="52px"
                />
              </Box>

              <Box ml={3} sx={{ flex: 1 }}>
                {/* Title */}
                {playlist?.title && (
                  <Text
                    sx={{
                      fontSize: ['l', null, null, null, 'xl'],
                      fontWeight: 'semibold',
                    }}
                  >
                    {playlist.title}
                  </Text>
                )}

                {/* Number of talks */}
                {playlist?.talkCount && (
                  <Box
                    mt={1}
                    sx={{
                      fontSize: 'xxs',
                    }}
                  >
                    {playlist.talkCount} {pluralize('Talk', playlist.talkCount)}
                  </Box>
                )}
              </Box>
            </Flex>
          </Flex>
        </a>
      </NextLink>
    </BoxResponsive>
  )
}

export default PlaylistCover
