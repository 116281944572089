import { useEffect, useState, useRef } from 'react'

type IntersectionObserverOptions = {
  rootMargin?: string
  threshold?: number | Array<number>
}

const useOnScreen = (
  ref: React.RefObject<HTMLElement>,
  options: {
    intersectionObserver?: IntersectionObserverOptions
    once: boolean
  } = {
    intersectionObserver: {
      rootMargin: '0px',
      threshold: 0,
    },
    once: false,
  },
  updateOn: any[] = [],
  callback?: (intersecting: boolean) => void
) => {
  const [isIntersecting, setIntersecting] = useState(false)
  const [position, setPosition] = useState<'above' | 'below'>()

  const refPreviousY = useRef(0)

  useEffect(() => {
    // eslint-disable-next-line
    const observer = new IntersectionObserver(([entry]) => {
      if (!ref.current) {
        return
      }

      const currentY = entry.boundingClientRect.y

      const previousY = refPreviousY.current

      // Update state when observer callback fires
      setIntersecting(entry.isIntersecting)

      if (!entry.isIntersecting) {
        if (currentY < previousY) {
          setPosition('above')
        } else {
          setPosition('below')
        }
      }

      refPreviousY.current = currentY

      // Fire optional callback
      if (callback) {
        callback(entry.isIntersecting)
      }

      // Stop observing
      if (options.once && entry.isIntersecting) {
        if (ref.current) {
          observer.unobserve(ref.current)
        }
      }
    }, options.intersectionObserver)

    if (ref.current) {
      observer.observe(ref.current)
    }

    // Stop observing on unmount
    return () => {
      if (ref.current) {
        observer.unobserve(ref.current)
      }
    }
  }, updateOn) // Empty array ensures that effect is only run on mount and unmount

  return {
    inViewport: isIntersecting,
    position,
  }
}

export default useOnScreen
