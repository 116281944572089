import { TeamMemberPreview } from '@types'
import React from 'react'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { AspectRatio, Box, Flex } from 'theme-ui'
import { teamMemberPanelActions } from '../../redux/modules/teamMemberPanel'
import { trackTeamBioClick } from '../../utils/analytics/trackTeamBioClick'
import Button from '../Button'
import ImageAvatar from '../ImageAvatar'
import Link from '../Link'

type Props = {
  circleIndex?: number
  item: TeamMemberPreview
}

const TeamMemberFigure = (props: Props) => {
  const { circleIndex, item } = props

  const [hover, setHover] = useState(false)

  // Redux
  const dispatch = useDispatch()

  // Handlers
  const handleShowTeamMember = () => {
    dispatch(
      teamMemberPanelActions.setVisible({
        teamMember: item,
        visible: true,
      })
    )

    // Analytics
    if (item?.name) {
      trackTeamBioClick(item.name)
    }
  }

  return (
    <Flex
      sx={{
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        minHeight: '275px',
        position: 'relative',
        width: '100%',
      }}
    >
      <Flex
        sx={{
          alignItems: 'center',
          flexDirection: 'column',
          flexGrow: 1,
          width: '100%',
        }}
      >
        {/* Image */}
        {item?.image && (
          <Box
            sx={{
              maxWidth: '300px',
              position: 'relative',
              width: '100%',
            }}
          >
            <Link
              onClick={() => handleShowTeamMember()}
              onMouseEnter={() => setHover(true)}
              onMouseLeave={() => setHover(false)}
            >
              <AspectRatio ratio={1}>
                <ImageAvatar
                  circleIndex={circleIndex}
                  image={item.image}
                  sizes="300px"
                  zoomed={hover}
                />
              </AspectRatio>
            </Link>
          </Box>
        )}

        <Box
          mt={3}
          sx={{
            textAlign: 'center',
            width: '100%',
          }}
        >
          {/* Name */}
          {item?.name && (
            <Box my={1}>
              <Link
                active={hover}
                onClick={() => handleShowTeamMember()}
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
                sx={{
                  fontSize: ['xs', null, null, 's'],
                  fontWeight: 'semibold',
                }}
                variant="simple"
              >
                {item.name}
              </Link>
            </Box>
          )}

          {/* Role */}
          {item?.role && (
            <Box
              color="slate"
              mt={1}
              mx="auto"
              sx={{
                fontSize: 'xs',
                lineHeight: 'body',
                maxWidth: '85%',
              }}
            >
              {item.role}
            </Box>
          )}

          {/* Read bio button */}
          <Button
            mt={4}
            onClick={() => handleShowTeamMember()}
            variant="outline"
          >
            Read bio
          </Button>
        </Box>
      </Flex>
    </Flex>
  )
}

export default TeamMemberFigure
