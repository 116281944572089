import { ThinkificChapters } from '@types'
import React from 'react'
import { Box } from 'theme-ui'
import useStaleSWR from '../../hooks/useStaleSWR'
import AccordionList from '../AccordionList'
import ModuleHeading from '../ModuleHeading'
import ModuleWrapper from '../ModuleWrapper'

type ModuleThinkificCourse = {
  _type: 'moduleThinkificCourse'
  courseId?: string
  heading: ModuleHeading
}

export type Props = {
  collapseMargins?: boolean
  module: ModuleThinkificCourse
}

const ModuleThinkificCourse = (props: Props) => {
  const { collapseMargins, module } = props

  // Fetch thinkific course outline on the client (only once)
  const { data: courseOutline, error } = useStaleSWR<ThinkificChapters[]>(
    `/api/thinkific/course/${module?.courseId}`
  )

  return (
    <ModuleWrapper collapseMargins={collapseMargins}>
      <Box
        mx="auto"
        pb={2}
        sx={{
          maxWidth: 'moduleTextOneColMaxWidth',
        }}
      >
        <ModuleHeading heading={module?.heading} />

        {/* Accordion */}
        {courseOutline && (
          <AccordionList
            sections={courseOutline?.map(chapter => ({
              list: chapter.lessons,
              title: chapter.name,
            }))}
          />
        )}
      </Box>
    </ModuleWrapper>
  )
}

export default ModuleThinkificCourse
