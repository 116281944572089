import { ColorTheme, SpeakerPreview } from '@types'
import React from 'react'
import { Box } from 'theme-ui'
import DividerHorizontalRandom from '../DividerHorizontalRandom'
import Eyebrow from '../Eyebrow'
import SpeakerProfile from '../SpeakerProfile'

type Props = {
  colorTheme?: ColorTheme
  eyebrow?: string
  speakers?: SpeakerPreview[]
}

const SpeakerProfiles = (props: Props) => {
  const { colorTheme, eyebrow, speakers } = props

  if (!speakers) {
    return null
  }

  return (
    <Box>
      {/* Eyebrow &*/}
      {eyebrow && <Eyebrow mb={[4, null, null, 6]}>{eyebrow}</Eyebrow>}

      {/* Speakers */}
      {speakers?.map((speaker, index) => (
        <Box
          key={index}
          mb={8}
          sx={{
            '&:last-of-type': {
              mb: 0,
            },
          }}
        >
          <SpeakerProfile colorTheme={colorTheme} speaker={speaker} />

          {index !== speakers.length - 1 && (
            <DividerHorizontalRandom mt={8} sx={{ opacity: 0.2 }} />
          )}
        </Box>
      ))}
    </Box>
  )
}

export default SpeakerProfiles
