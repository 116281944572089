import { Vimeo } from '@types'
import React, { useEffect, useRef, useState } from 'react'
import ReactPlayer from 'react-player'
import { AspectRatio, Box, Flex } from 'theme-ui'
import useOnScreen from '../../hooks/useOnScreen'
import usePageVisibility from '../../hooks/usePageVisibility'
import useTypedSelector from '../../hooks/useTypedSelector'
import { selectMeasurementConsent } from '../../redux/modules/iubenda'
import { trackVideoPlay } from '../../utils/analytics/trackVideoPlay'
import ButtonIcon from '../ButtonIcon'
import IubendaMeasurementNotice from '../IubendaMeasurementNotice'

type Props = {
  vimeo: Vimeo
}

const SimpleVimeoVideo = (props: Props) => {
  const { vimeo } = props
  const aspectRatio = vimeo?.source?.dimensions?.aspectRatio || 16 / 9

  // Redux
  const measurementConsent = useTypedSelector(selectMeasurementConsent)

  // Refs
  const refContainer = useRef(null)

  // State
  const [playing, setPlaying] = useState(true)
  const [muted, setMuted] = useState(true)

  const { inViewport } = useOnScreen(refContainer)
  const visibility = usePageVisibility()

  // Effects
  // - Pause / resume when video is in viewport / page is visible
  useEffect(() => {
    const isPlaying = inViewport && visibility
    setPlaying(isPlaying)
  }, [inViewport, visibility])

  // Callbacks
  const handleToggleMute = () => {
    setMuted(prevMuted => !prevMuted)
  }

  const handleTogglePlay = () => {
    setPlaying(prevPlaying => !prevPlaying)
  }

  const handleTrackPlay = () => {
    if (vimeo?.source?.name) {
      trackVideoPlay(vimeo.source.name)
    }
  }

  return (
    <AspectRatio
      bg="midnight"
      ratio={aspectRatio}
      ref={refContainer}
      sx={{
        borderRadius: 1,
        overflow: 'hidden',
      }}
    >
      {measurementConsent ? (
        <>
          <ReactPlayer
            config={{
              vimeo: {
                playerOptions: {
                  background: true,
                },
              },
            }}
            muted={muted}
            height="100%"
            onPlay={handleTrackPlay}
            playing={playing}
            width="100%"
            url={vimeo?.url}
            volume={1}
          />

          {/* Controls */}
          {/* Toggle playback (fullscreen) */}
          <Box
            onClick={handleTogglePlay}
            sx={{
              height: '100%',
              left: 0,
              opacity: 0,
              position: 'absolute',
              top: 0,
              width: '100%',
            }}
          />

          <Flex
            sx={{
              bottom: 2,
              flexDirection: 'column',
              position: 'absolute',
              right: 2,
            }}
          >
            {/* Play / pause */}
            <ButtonIcon
              background="white"
              color="white"
              iconSize={12}
              onClick={handleTogglePlay}
              size={34}
              transparent={true}
              type={playing ? 'pause' : 'play'}
            />

            {/* Mute */}
            <Box mt={2}>
              <ButtonIcon
                background="white"
                color="white"
                iconSize={16}
                onClick={handleToggleMute}
                size={34}
                transparent={true}
                type={muted ? 'volumeMute' : 'volumeHigh'}
              />
            </Box>
          </Flex>
        </>
      ) : (
        <IubendaMeasurementNotice />
      )}
    </AspectRatio>
  )
}

export default SimpleVimeoVideo
