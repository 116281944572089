import { SanityImage } from '@types'
import React from 'react'
import { Box, Grid } from 'theme-ui'
import Cover from '../Cover'
import IndexSubheader from '../IndexSubheader'
import ModuleHeading from '../ModuleHeading'
import ModuleWrapper from '../ModuleWrapper'

type ModuleCoverLinks = {
  _type: 'moduleCoverLinks'
  heading: ModuleHeading
  links: {
    description?: string
    image: SanityImage
    label: string
    title: string
    url: string
  }[]
  title: string
}

export type Props = {
  collapseMargins?: boolean
  module: ModuleCoverLinks
}

const ModuleCoverLinks = (props: Props) => {
  const { collapseMargins, module } = props

  const gridColumns = [1, 1, 1, 3]
  const gridTemplateColumns = gridColumns.map(
    numCols => `repeat(${numCols}, 1fr)`
  )

  return (
    <ModuleWrapper collapseMargins={collapseMargins}>
      <ModuleHeading heading={module?.heading} />

      {module?.title && <IndexSubheader large title={module.title} />}

      <Grid
        sx={{
          columnGap: 6,
          gridTemplateColumns,
          rowGap: 7,
          width: '100%',
        }}
      >
        {module?.links?.map((link, index) => (
          <Box key={index}>
            <Cover
              cover={{
                label: link.label,
                linkedLayout: {
                  image: link.image,
                  subtitle: link?.description,
                },
                slug: link.url,
                title: link.title,
              }}
              imageObjectFit="contain"
            />
          </Box>
        ))}
      </Grid>
    </ModuleWrapper>
  )
}

export default ModuleCoverLinks
