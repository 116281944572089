import { ColorTheme, SanityBlock } from '@types'
import React from 'react'
import { Box, Flex } from 'theme-ui'
import ModuleWrapper from '../ModuleWrapper'
import PortableText from '../PortableText'

type ModuleTextTwoColumn = {
  _type: 'moduleTextTwoColumn'
  bodyLeft?: SanityBlock[]
  bodyRight?: SanityBlock[]
}

export type Props = {
  collapseMargins?: boolean
  colorTheme?: ColorTheme
  module: ModuleTextTwoColumn
}

const ModuleTextTwoColumn = (props: Props) => {
  const { collapseMargins, colorTheme, module } = props

  const marginX = [0, null, null, 10]

  return (
    <ModuleWrapper collapseMargins={collapseMargins}>
      <Flex
        color={colorTheme?.text}
        mx="auto"
        sx={{
          flexDirection: ['column', null, null, 'row'],
          maxWidth: [
            'moduleTextOneColMaxWidth',
            null,
            null,
            'moduleTextTwoColMaxWidth',
          ],
          width: '100%',
        }}
      >
        {/* Body (left) */}
        <Box mb={[5, null, null, 0]} mr={marginX} sx={{ flex: 1 }}>
          {module?.bodyLeft && (
            <PortableText blocks={module.bodyLeft} variant="body" />
          )}
        </Box>

        {/* Body (right) */}
        <Box ml={marginX} sx={{ flex: 1 }}>
          {module?.bodyRight && (
            <PortableText blocks={module.bodyRight} variant="body" />
          )}
        </Box>
      </Flex>
    </ModuleWrapper>
  )
}

export default ModuleTextTwoColumn
