import React from 'react'
import { Box } from 'theme-ui'
import Eyebrow from '../Eyebrow'
import H2 from '../H2'

type Props = {
  heading?: ModuleHeading
}

type ModuleHeading = {
  align: 'center' | 'left'
  description?: string
  eyebrow?: string
  title?: string
}

const ModuleHeading = (props: Props) => {
  const { heading } = props

  if (!heading || (!heading.eyebrow && !heading.title)) {
    return null
  }

  return (
    <Box mb={[7, null, null, 9]} sx={{ textAlign: heading.align || 'left' }}>
      <Box
        sx={{
          display: 'inline-block',
          maxWidth: '600px',
          textAlign: heading.align,
        }}
      >
        {/* Eyebrow */}
        {heading?.eyebrow && <Eyebrow mb={5}>{heading.eyebrow}</Eyebrow>}
        {/* Title */}
        {heading?.title && <H2>{heading.title}</H2>}
        {/* Description */}
        {heading.description && (
          <Box
            mt={4}
            mx="auto"
            sx={{
              fontSize: ['xs', null, null, 's'],
              lineHeight: 'body',
              maxWidth: '425px',
            }}
          >
            {heading.description}
          </Box>
        )}
      </Box>
    </Box>
  )
}

export default ModuleHeading
