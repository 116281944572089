import { SanityBlock } from '@types'
import React from 'react'
import { Box } from 'theme-ui'
import ModuleWrapper from '../ModuleWrapper'
import PortableText from '../PortableText'

type ModuleSectionHeading = {
  _type: 'moduleSectionHeading'
  description?: string
  title: SanityBlock[]
}

type Props = {
  collapseMargins?: boolean
  module: ModuleSectionHeading
}

const ModuleSectionHeading = (props: Props) => {
  const { collapseMargins, module } = props

  return (
    <ModuleWrapper collapseMargins={collapseMargins}>
      {module?.title && (
        <Box
          mx={2}
          pb={4}
          sx={{
            p: {
              fontSize: ['xxl', null, null, 'xxxl'],
              fontWeight: 'semibold',
              lineHeight: 'single',
              textAlign: 'center',
            },
          }}
        >
          <PortableText blocks={module.title} />
        </Box>
      )}

      {module?.description && (
        <Box
          mx="auto"
          sx={{
            fontSize: ['xs', null, null, 's'],
            lineHeight: 'body',
            maxWidth: '426px',
            textAlign: 'center',
          }}
        >
          {module.description}
        </Box>
      )}
    </ModuleWrapper>
  )
}

export default ModuleSectionHeading
