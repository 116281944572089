import { ColorTheme, Vimeo } from '@types'
import React from 'react'
import { Box } from 'theme-ui'
import Caption from '../Caption'
import ModuleWrapper from '../ModuleWrapper'
import PlyrVideo from '../PlyrVideo'
import SimpleVimeoVideo from '../SimpleVimeoVideo'

type ModuleVideo = {
  _type: 'moduleVideo'
  background?: boolean
  caption?: string
  vimeo: Vimeo
}

export type Props = {
  collapseMargins?: boolean
  colorTheme?: ColorTheme
  module: ModuleVideo
}

const ModuleVideo = (props: Props) => {
  const { collapseMargins, colorTheme, module } = props

  if (!module?.vimeo?.url || !module?.vimeo?.source?.id) {
    return null
  }

  return (
    <ModuleWrapper collapseMargins={collapseMargins}>
      <Box
        sx={{
          borderRadius: 1,
          overflow: 'hidden',
        }}
      >
        {/* Video */}
        {module?.background ? (
          <SimpleVimeoVideo vimeo={module.vimeo} />
        ) : (
          <PlyrVideo vimeo={module.vimeo} />
        )}

        {/* Caption */}
        <Caption color={colorTheme?.text} text={module?.caption} />
      </Box>
    </ModuleWrapper>
  )
}

export default ModuleVideo
