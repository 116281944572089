import { SanityImage } from '@types'
import React from 'react'
import { Box, Text } from 'theme-ui'
import BoxResponsive from '../BoxResponsive'
import Button from '../Button'
import ModuleHeading from '../ModuleHeading'
import ModuleWrapper from '../ModuleWrapper'
import NextImage from '../NextImage'

type ModuleLocations = {
  _type: 'moduleLocations'
  heading: ModuleHeading
  locations: {
    image: SanityImage
    title: string
    url?: string
    when: string
    where: string
  }[]
}

export type Props = {
  collapseMargins?: boolean
  module: ModuleLocations
}

const ModuleLocations = (props: Props) => {
  const { collapseMargins, module } = props

  return (
    <ModuleWrapper collapseMargins={collapseMargins}>
      <Box
        mx="auto"
        sx={{
          maxWidth: 'moduleTextOneColMaxWidth',
        }}
      >
        <ModuleHeading heading={module?.heading} />

        {/* Locations */}
        {module?.locations &&
          module?.locations?.map((location, index) => {
            const { image, title, when, where, url } = location
            return (
              <Box
                key={index}
                mb={8}
                sx={{
                  '&:last-of-type': {
                    mb: 0,
                  },
                }}
              >
                {image && (
                  <a href={url} target="_blank">
                    <BoxResponsive
                      aspect={[647 / 418]}
                      sx={{ borderRadius: 1, overflow: 'hidden' }}
                    >
                      <NextImage
                        image={image}
                        layout="fill"
                        sizes={['100vw', null, null, '50vw']}
                      />
                    </BoxResponsive>
                  </a>
                )}

                <Box
                  mt={6}
                  sx={{
                    fontWeight: 'semibold',
                  }}
                >
                  {/* Title */}
                  <Box sx={{ fontSize: 'l', lineHeight: 'single' }}>
                    {title}
                  </Box>

                  <Box mt={5} sx={{ fontSize: 'm' }}>
                    {/* Where */}
                    {where && (
                      <Box>
                        <Text color="slate">Where:</Text> {where}
                      </Box>
                    )}

                    {/* When */}
                    {when && (
                      <Box>
                        <Text color="slate">When:</Text> {when}
                      </Box>
                    )}
                  </Box>

                  {/* Button */}
                  {url && (
                    <a href={url} target="_blank">
                      <Button sx={{ mt: 5 }} variant="outline">
                        Get directions
                      </Button>
                    </a>
                  )}
                </Box>
              </Box>
            )
          })}
      </Box>
    </ModuleWrapper>
  )
}

export default ModuleLocations
