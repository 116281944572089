import {
  ArticlePreview,
  ColorTheme,
  EventPreview,
  ManifestoPreview,
  OnlineCoursePreview,
  PlaylistPreview,
  ProductPreview,
  ReadingListPreview,
  TalkPreview,
  WorkshopPreview,
} from '@types'
import NextLink from 'next/link'
import { rgba } from 'polished'
import React, { useState } from 'react'
import { AspectRatio, Box, Flex, ThemeUIStyleObject } from 'theme-ui'
import { COLORS } from '../../constants'
import { CurrencyCode } from '../../types/codegen/shopify'
import isShoppableGuard from '../../utils/isShoppableGuard'
import Artboard from '../Artboard'
import CardDetails from '../CardDetails'
import DocumentTypeLabel from '../DocumentTypeLabel'
import Eyebrow from '../Eyebrow'
import Link from '../Link'
import NextImageZoomable from '../NextImageZoomable'
import ProductPresentmentPriceRange from '../ProductPresentmentPriceRange'
import ReferencesPrimary from '../ReferencesPrimary'
import ReferencesTags from '../ReferencesTags'

type Card =
  | ArticlePreview
  | EventPreview
  | ManifestoPreview
  | OnlineCoursePreview
  | PlaylistPreview
  | ProductPreview
  | ReadingListPreview
  | TalkPreview
  | WorkshopPreview

export type Props = {
  colorTheme?: ColorTheme
  displayAsHit?: boolean
  item: Card
  sx?: ThemeUIStyleObject
}

const Card = (props: Props) => {
  const { colorTheme, displayAsHit, item, sx } = props

  // State
  const [hover, setHover] = useState(false)

  const href = item.slug

  let anyVariantAvailable
  if (isShoppableGuard(item)) {
    const productVariants = item?.shopify?.variants || []
    anyVariantAvailable = productVariants.reduce((acc, val) => {
      if (val.inStock) {
        acc = true
      }
      return acc
    }, false as boolean)
  }

  const isProduct = item._type === 'product'

  const color = colorTheme?.text || 'midnight'
  const colorPrimaryReferences = rgba(
    COLORS[colorTheme?.text || 'midnight'],
    0.7
  )

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        // NOTE: `height: 100%` causes layout issues on Safari!
        // height: '100%',
        position: 'relative',
        width: '100%',
        ...sx,
      }}
    >
      {/* Image */}
      <Box
        bg="lightGray"
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        sx={{
          borderRadius: '2px', // TODO: use theme value
          overflow: 'hidden',
          width: '100%',
        }}
      >
        <AspectRatio ratio={423 / 239}>
          <NextLink href={href}>
            <a>
              {item?.image && (
                <Flex
                  sx={{
                    alignItems: 'center',
                    flex: 1,
                    flexDirection: 'column',
                    height: '100%',
                    justifyContent: 'center',
                    position: 'relative',
                  }}
                >
                  {/* Image */}
                  {item._type !== 'playlist' && (
                    <Box
                      sx={{
                        flexShrink: 0,
                        height: isProduct ? '60%' : '100%',
                        position: 'relative',
                        width: isProduct ? '80%' : '100%',
                      }}
                    >
                      <NextImageZoomable
                        image={item.image}
                        layout="fill"
                        objectFit={isProduct ? 'contain' : 'cover'}
                        sizes={['80vw', null, null, '30vw']}
                        zoomed={hover}
                      />
                    </Box>
                  )}

                  {/* Playlist: Artboard */}
                  {item._type === 'playlist' && (
                    <Artboard artboard={item.artboard} />
                  )}

                  {/* Badge */}
                  {displayAsHit && (
                    <DocumentTypeLabel
                      item={item}
                      sx={{
                        left: 2,
                        position: 'absolute',
                        top: 2,
                      }}
                    />
                  )}

                  {/* Card details */}
                  <Box
                    sx={{
                      bottom: 0,
                      left: 0,
                      position: 'absolute',
                      width: '100%',
                    }}
                  >
                    <CardDetails item={item} />
                  </Box>
                </Flex>
              )}
            </a>
          </NextLink>
        </AspectRatio>
      </Box>

      {/* Info */}
      <Flex mt={3} sx={{ flexDirection: 'row' }}>
        <Box sx={{ flex: 1 }}>
          <Box>
            {/* Title */}
            {item?.title && (
              <Box
                color={color}
                sx={{
                  fontSize: 's',
                  fontWeight: 'semibold',
                }}
              >
                <NextLink href={href} passHref>
                  <Link
                    active={hover}
                    onMouseEnter={() => setHover(true)}
                    onMouseLeave={() => setHover(false)}
                    variant="simple"
                  >
                    {item.title}
                  </Link>
                </NextLink>
              </Box>
            )}

            {/* Price / sold out */}
            {isShoppableGuard(item) && item.shopify && (
              <Box
                mt={1}
                sx={{
                  flexShrink: 0,
                  fontSize: 'xs',
                  fontWeight: 'semibold',
                }}
              >
                {anyVariantAvailable ? (
                  <ProductPresentmentPriceRange
                    compareAtPriceRange={{
                      maxVariantPrice: {
                        amount:
                          item.shopify?.compareAtPriceRange?.maxVariantPrice,
                        currencyCode: 'GBP' as CurrencyCode,
                      },
                      minVariantPrice: {
                        amount:
                          item.shopify?.compareAtPriceRange?.minVariantPrice,
                        currencyCode: 'GBP' as CurrencyCode,
                      },
                    }}
                    displaySingleVariantComparePrice
                    priceRange={{
                      maxVariantPrice: {
                        amount: item.shopify?.priceRange?.maxVariantPrice,
                        currencyCode: 'GBP' as CurrencyCode,
                      },
                      minVariantPrice: {
                        amount: item.shopify?.priceRange?.minVariantPrice,
                        currencyCode: 'GBP' as CurrencyCode,
                      },
                    }}
                  />
                ) : (
                  <Eyebrow color="slate" mt={2}>
                    Sold out
                  </Eyebrow>
                )}
              </Box>
            )}

            {/* Primary card references (including speaker) */}
            <Box
              color={colorPrimaryReferences}
              mt={1}
              sx={{
                fontSize: 'xs',
                lineHeight: 'body',
              }}
            >
              <ReferencesPrimary item={item} />
            </Box>
          </Box>

          {/* Tag references */}
          <Box mt={2}>
            <ReferencesTags
              item={item}
              tone={
                colorTheme?.background
                  ? colorTheme.header === 'midnight'
                    ? 'dark'
                    : 'light'
                  : 'blue'
              }
            />
          </Box>
        </Box>
      </Flex>
    </Box>
  )
}

export default Card
