import { useEffect, useState } from 'react'

function useTouchCapable() {
  const [value, setValue] = useState<boolean>()
  useEffect(() => {
    setValue('ontouchstart' in window)
  }, [])

  return value
}

export default useTouchCapable
