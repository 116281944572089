import {
  ArticlePreview,
  EventPreview,
  ManifestoPreview,
  OnlineCoursePreview,
  PlaylistPreview,
  ProductPreview,
  ReadingListPreview,
  TalkPreview,
  WorkshopPreview,
} from '@types'

const isShoppableGuard = (
  item:
    | ArticlePreview
    | EventPreview
    | ManifestoPreview
    | OnlineCoursePreview
    | PlaylistPreview
    | ProductPreview
    | ReadingListPreview
    | TalkPreview
    | WorkshopPreview
): item is OnlineCoursePreview | ProductPreview | WorkshopPreview => {
  return ['onlineCourse', 'product', 'workshop'].includes(item._type)
}

export default isShoppableGuard
