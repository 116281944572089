import { ColorTheme, IllustrationThickType, SanityImage } from '@types'
import React from 'react'
import { Box, Flex, Grid } from 'theme-ui'
import BoxResponsive from '../BoxResponsive'
import DividerVertical from '../DividerVertical'
import IllustrationThick from '../IllustrationThick'
import MaskCircleRandom from '../MaskCircleRandom'
import ModuleHeading from '../ModuleHeading'
import ModuleWrapper from '../ModuleWrapper'
import NextImage from '../NextImage'
import NextImageResponsive from '../NextImageResponsive'

type ModuleFeatures = {
  _type: 'moduleFeatures'
  features?: {
    description: string
    illustration: IllustrationThickType
    image?: SanityImage
    title: string
  }[]
  heading: ModuleHeading
}

export type Props = {
  collapseMargins?: boolean
  colorTheme?: ColorTheme
  module: ModuleFeatures
}

const ModuleFeatures = (props: Props) => {
  const { collapseMargins, colorTheme, module } = props

  return (
    <ModuleWrapper collapseMargins={collapseMargins}>
      <Box>
        <ModuleHeading heading={module?.heading} />

        {/* Features */}
        {module?.features && (
          <Grid
            mt={[6, null, null, 12]}
            sx={{
              // flexDirection: ['column', null, null, 'row'],
              rowGap: [5, null, null, 10],
              gridTemplateColumns: [
                'repeat(1, 1fr)',
                null,
                null,
                'repeat(3, 1fr)',
              ],
            }}
          >
            {module.features?.map((feature, index) => {
              const maskId = `feature-${index}`
              return (
                <Flex
                  key={index}
                  sx={{
                    flex: 1,
                    maxWidth: ['400px', null, null, 'none'],
                    mx: ['auto', null, null, 0],
                  }}
                >
                  {/* Vertical divider */}
                  <Box
                    color="stone"
                    sx={{
                      display: ['none', null, null, 'block'],
                    }}
                  >
                    <DividerVertical />
                  </Box>

                  <Box ml={[0, null, null, 7]} pr={[0, null, null, 6]}>
                    {/* Image (takes precedence over illustration) */}
                    {feature?.image && (
                      <Box
                        mx={['auto', null, null, 0]}
                        sx={{
                          position: 'relative',
                          width: ['50vw', null, null, '144px'],
                        }}
                      >
                        <BoxResponsive aspect={[1]}>
                          <NextImageResponsive
                            image={feature.image}
                            sizes={['50vw', null, null, '144px']}
                            sx={{
                              borderRadius: 1,
                              overflow: 'hidden',
                            }}
                          />
                        </BoxResponsive>
                      </Box>
                    )}

                    {/* Illustration (only if no image is provided) */}
                    {!feature?.image && feature?.illustration && (
                      <Flex
                        bg={colorTheme?.shape}
                        color={colorTheme?.text}
                        mx={['auto', null, null, 0]}
                        sx={{
                          alignItems: 'center',
                          clipPath: `url(#${maskId})`,
                          height: ['52px', null, null, '60px'],
                          justifyContent: 'center',
                          width: ['60px', null, null, '68px'],
                        }}
                      >
                        <MaskCircleRandom
                          maskId={maskId}
                          patternIndex={index}
                        />
                        <Box
                          sx={{
                            height: ['30px', null, null, '34px'],
                            width: ['30px', null, null, '34px'],
                          }}
                        >
                          <IllustrationThick type={feature.illustration} />
                        </Box>
                      </Flex>
                    )}

                    <Box sx={{ maxWidth: '600px' }}>
                      {/* Title */}
                      {feature?.title && (
                        <Box
                          mt={3}
                          sx={{
                            fontSize: ['m', null, null, 'l'],
                            fontWeight: 'semibold',
                            textAlign: ['center', null, null, 'left'],
                          }}
                        >
                          {feature.title}
                        </Box>
                      )}

                      {/* Description */}
                      {feature?.description && (
                        <Box
                          mt={3}
                          sx={{
                            fontSize: ['xs', null, null, 's'],
                            lineHeight: 'body',
                            textAlign: ['center', null, null, 'left'],
                          }}
                        >
                          {feature.description}
                        </Box>
                      )}
                    </Box>
                  </Box>
                </Flex>
              )
            })}
          </Grid>
        )}
      </Box>
    </ModuleWrapper>
  )
}

export default ModuleFeatures
