import {
  ColorTheme,
  SanityLinkExternal,
  SanityLinkInternal,
  SanityLinkStub,
} from '@types'
import React, { useState } from 'react'
import { Box, Flex, Heading } from 'theme-ui'
import useTouchCapable from '../../hooks/useTouchCapable'
import { trackBannerClick } from '../../utils/analytics/trackBannerClick'
import BannerShapes from '../BannerShapes'
import Button from '../Button'
import Eyebrow from '../Eyebrow'
import LinkSanity from '../LinkSanity'

type Banner = {
  _type: 'banner'
  colorTheme?: ColorTheme
  eyebrow: string
  link: (SanityLinkExternal | SanityLinkInternal | SanityLinkStub)[]
  title: string
}

type Props = {
  banner: Banner
  colorTheme?: ColorTheme
}

const Banner = (props: Props) => {
  const { banner, colorTheme: colorThemeOverride } = props

  // State
  const [hover, setHover] = useState(false)

  // Use supplied colorTheme if provided, otherwise fallback to module's own theme
  const colorTheme = colorThemeOverride || banner?.colorTheme

  const link = banner?.link?.[0]

  const isTouch = useTouchCapable()

  if (!link) {
    return null
  }

  const handleTrackClick = (
    link: SanityLinkExternal | SanityLinkInternal | SanityLinkStub
  ) => {
    let slug
    if (link._type === 'linkInternal') {
      slug = link.slug
    }
    if (link._type === 'linkExternal') {
      slug = link.url
    }
    if (link._type === 'linkStub') {
      slug = `(${link.type})`
    }

    if (slug) {
      // Analytics
      trackBannerClick(slug)
    }
  }

  return (
    <Box
      bg={colorTheme?.background || 'white'}
      onClick={handleTrackClick.bind(undefined, link)}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      sx={{
        borderRadius: 1,
        overflow: 'hidden',
        position: 'relative',
      }}
    >
      <LinkSanity link={link}>
        {/* Shapes */}
        <BannerShapes
          color={colorTheme?.shape}
          visible={isTouch ? true : hover}
        />

        {/* Content */}
        <Flex
          color={colorTheme?.text || 'midnight'}
          mx="auto"
          py={20}
          sx={{
            alignItems: 'center',
            flex: 1,
            flexDirection: 'column',
            height: '100%',
            justifyContent: 'center',
            position: 'relative',
            textAlign: 'center',
          }}
        >
          {/* Eyebrow */}
          {banner?.eyebrow && <Eyebrow>{banner.eyebrow}</Eyebrow>}

          {/* Title */}
          {banner?.title && (
            <Heading
              mt={[5, null, 4]}
              sx={{
                fontSize: ['l', null, 'xl'],
                fontWeight: 'semibold',
                maxWidth: ['300px', null, '625px'],
              }}
            >
              {banner?.title}
            </Heading>
          )}

          {/* Button */}
          <Box mt={[5, null, null, 4]}>
            <Button
              active={hover}
              background={colorTheme?.text}
              color={colorTheme?.background}
              variant="outline"
            >
              {link.title}
            </Button>
          </Box>
        </Flex>
      </LinkSanity>
    </Box>
  )
}

export default Banner
