import React, { ReactNode } from 'react'
import { Box } from 'theme-ui'

type Props = {
  children: ReactNode
  collapseMargins?: boolean
  fullWidth?: boolean
}

const ModuleWrapper = (props: Props) => {
  const { children, collapseMargins, fullWidth } = props

  return (
    <Box
      my={[8, null, null, 12]}
      px={fullWidth ? 0 : [4, null, null, 6]}
      sx={{
        '&:first-of-type': {
          mt: collapseMargins && 0,
        },
        '&:last-of-type': {
          mb: collapseMargins && 0,
        },
      }}
    >
      {children}
    </Box>
  )
}

export default ModuleWrapper
