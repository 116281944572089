import { SanityImage } from '@types'
import React from 'react'
import ImagePair from '../ImagePair'
import ModuleWrapper from '../ModuleWrapper'

type ModuleImageTwoColumn = {
  _type: 'moduleImageTwoColumn'
  imageLarge: SanityImage
  imageSmall: SanityImage
  layout: 'largeLeft' | 'largeRight'
}

export type Props = {
  collapseMargins?: boolean
  module: ModuleImageTwoColumn
}

const ModuleImageTwoColumn = (props: Props) => {
  const { collapseMargins, module } = props

  return (
    <ModuleWrapper collapseMargins={collapseMargins}>
      <ImagePair
        imageLarge={module?.imageLarge}
        imageSmall={module?.imageSmall}
        layout={module?.layout}
      />
    </ModuleWrapper>
  )
}

export default ModuleImageTwoColumn
