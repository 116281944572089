import { ColorTheme, SpeakerPreview } from '@types'
import React from 'react'
import { Box } from 'theme-ui'
import ModuleHeading from '../ModuleHeading'
import ModuleWrapper from '../ModuleWrapper'
import SpeakerProfiles from '../SpeakerProfiles'

type ModuleSpeakerProfiles = {
  _type: 'moduleSpeakerProfiles'
  heading: ModuleHeading
  speakers?: SpeakerPreview[]
}

export type Props = {
  collapseMargins?: boolean
  colorTheme?: ColorTheme
  module: ModuleSpeakerProfiles
}

const ModuleSpeakerProfiles = (props: Props) => {
  const { collapseMargins, colorTheme, module } = props

  return (
    <ModuleWrapper collapseMargins={collapseMargins}>
      <Box
        mx="auto"
        sx={{
          maxWidth: 'moduleTextOneColMaxWidth',
        }}
      >
        <ModuleHeading heading={module?.heading} />

        {/* Speakers */}
        <SpeakerProfiles colorTheme={colorTheme} speakers={module?.speakers} />
      </Box>
    </ModuleWrapper>
  )
}

export default ModuleSpeakerProfiles
