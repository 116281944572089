import { ButtonVariant, Color, ShopifyProduct } from '@types'
import React from 'react'
import { ThemeUIStyleObject } from 'theme-ui'
import Button from '../Button'
import ButtonAddVariantToCart from '../ButtonAddVariantToCart'
import ButtonDisplayVariantPanel from '../ButtonDisplayVariantPanel'

type Props = {
  background?: Color
  color?: Color
  product?: ShopifyProduct
  productTitle: string
  sx?: ThemeUIStyleObject
  variant?: ButtonVariant
}

const ButtonProductContextual = (props: Props) => {
  const { background, color, product, productTitle, sx, variant } = props

  if (!product) {
    return null
  }

  const productVariants = product.variants || []
  const firstVariant = productVariants[0]
  const hasMultipleVariants = productVariants.length > 1
  const anyVariantAvailable = productVariants.reduce((acc, val) => {
    if (val.inStock) {
      acc = true
    }
    return acc
  }, false as boolean)

  if (!anyVariantAvailable) {
    return (
      <Button
        background={background}
        color={color}
        disabled
        sx={sx}
        variant={variant}
      >
        Sold out
      </Button>
    )
  }

  if (hasMultipleVariants) {
    return (
      <ButtonDisplayVariantPanel
        background={background}
        color={color}
        productTitle={productTitle}
        product={product}
        singlePurchase={product.singlePurchase}
        sx={sx}
        variant={variant}
      />
    )
  }

  if (firstVariant) {
    return (
      <ButtonAddVariantToCart
        background={background}
        color={color}
        productVariant={firstVariant}
        singlePurchase={product.singlePurchase}
        sx={sx}
        variant={variant}
      />
    )
  }

  return null
}

export default ButtonProductContextual
