import { ColorTheme, WorkshopPreview } from '@types'
import React from 'react'
import { Box } from 'theme-ui'
import CardLarge from '../CardLarge'
import IndexSubheader from '../IndexSubheader'
import ModuleWrapper from '../ModuleWrapper'

type ModuleWorkshop = {
  _type: 'moduleWorkshop'
  workshop: WorkshopPreview
}

export type Props = {
  collapseMargins?: boolean
  colorTheme?: ColorTheme
  module: ModuleWorkshop
}

const ModuleWorkshop = (props: Props) => {
  const { collapseMargins, colorTheme, module } = props

  return (
    <ModuleWrapper collapseMargins={collapseMargins}>
      <Box color={colorTheme?.text}>
        <IndexSubheader
          illustrationType="create"
          large
          title="Featured workshop"
        />
      </Box>

      {module?.workshop && (
        <CardLarge colorTheme={colorTheme} item={module.workshop} />
      )}
    </ModuleWrapper>
  )
}

export default ModuleWorkshop
