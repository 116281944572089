import { PlaylistPreview } from '@types'
import NextLink from 'next/link'
import pluralize from 'pluralize'
import React, { useState } from 'react'
import { Box, Flex } from 'theme-ui'
import Artboard from '../Artboard'
import BoxResponsive from '../BoxResponsive'
import Button from '../Button'
import NextImageZoomable from '../NextImageZoomable'

export type Props = {
  playlist: PlaylistPreview
}

const PlaylistCardLarge = (props: Props) => {
  const { playlist } = props

  // State
  const [hover, setHover] = useState(false)

  return (
    <BoxResponsive aspect={[342 / 498, null, null, 1318 / 529]}>
      {/* Artboard */}
      {playlist?.artboard && <Artboard artboard={playlist.artboard} />}

      <NextLink href={playlist.slug}>
        <a>
          <Flex
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            sx={{
              alignItems: 'stretch',
              flexDirection: ['column-reverse', null, null, 'row'],
              height: '100%',
              justifyContent: 'center',
            }}
          >
            {/* Content */}
            <Flex
              color={playlist.artboard?.colorTheme.text}
              sx={{
                alignItems: ['none', null, null, 'center'],
                flex: [0.5, null, null, 0.4],
                position: 'relative',
              }}
            >
              <Box
                ml={[5, null, null, 6]}
                mt={[6, null, null, 0]}
                sx={{ position: 'relative' }}
              >
                {/* Title */}
                <Box
                  as="h2"
                  sx={{
                    fontSize: ['l', null, null, 'xl'],
                    fontWeight: 'semibold',
                  }}
                >
                  {playlist.title}
                </Box>

                {/* Talk count */}
                <Box
                  mb={4}
                  mt={2}
                  sx={{
                    fontSize: ['s', null, null, 'm'],
                    fontWeight: 'semibold',
                    opacity: 0.5,
                  }}
                >
                  {playlist.talkCount} {pluralize('talk', playlist.talkCount)}
                </Box>

                {/* Excerpt */}
                {playlist?.description && (
                  <Box
                    my={4}
                    sx={{
                      fontSize: ['xs', null, null, 's'],
                      lineHeight: 'body',
                      maxWidth: '390px',
                    }}
                  >
                    {playlist.description}
                  </Box>
                )}

                {/* Button */}
                <Button
                  active={hover}
                  background={playlist.artboard?.colorTheme.text}
                  color={playlist.artboard?.colorTheme.background}
                  variant="outline"
                >
                  Watch now
                </Button>
              </Box>
            </Flex>

            {/* Image */}
            <Box
              sx={{
                flex: [0.5, null, null, 0.6],
                maskImage: [
                  'linear-gradient(to top, transparent 0%, black 35%)',
                  null,
                  null,
                  'linear-gradient(to right, transparent 0%, black 35%)',
                ],
                position: 'relative',
              }}
            >
              {playlist?.image && (
                <NextImageZoomable
                  image={playlist.image}
                  layout="fill"
                  sizes={['45vw']}
                  zoomed={hover}
                />
              )}
            </Box>
          </Flex>
        </a>
      </NextLink>
    </BoxResponsive>
  )
}

export default PlaylistCardLarge
