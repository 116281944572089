import { CollectionPreview } from '@types'
import React from 'react'
import Cover from '../Cover'

type Props = {
  item: CollectionPreview
}

const CollectionCover = (props: Props) => {
  const { item } = props
  return (
    <Cover
      aspect={[343 / 339, null, null, null, 647 / 418]}
      cover={{
        label: 'Shop now',
        linkedLayout: item?.linkedLayout,
        slug: item.slug,
        title: item.title,
      }}
      imageObjectFit="contain"
    />
  )
}

export default CollectionCover
