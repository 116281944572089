import React from 'react'
import { Box } from 'theme-ui'

type Props = {}

const OverlayRadialGradient = (props: Props) => {
  return (
    <Box
      sx={{
        background: `radial-gradient(rgba(20, 23, 21, 0), #171414);`,
        height: '100%',
        left: 0,
        position: 'absolute',
        top: 0,
        width: '100%',
      }}
    />
  )
}

export default OverlayRadialGradient
