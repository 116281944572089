import { Reference, SanityDocumentTypeSingleton } from '@types'
import NextLink from 'next/link'
import React, { FunctionComponent, ReactNode } from 'react'
import { Box, BoxOwnProps, Flex, Grid, ThemeUIStyleObject } from 'theme-ui'
import getSingletonHref from '../../utils/getSingletonHref'
import Button from '../Button'
import Eyebrow from '../Eyebrow'
import Tags from '../Tags'

type Props = {
  columns?: (number | null)[]
  eyebrow?: string
  linkDocumentTypeIndex?: SanityDocumentTypeSingleton
  linkLabel?: string
  nodes?: ReactNode[]
  references?: Reference[]
  rowGap?: number | number[]
  small?: boolean
  sx?: ThemeUIStyleObject
  title?: string
}

const GridWrapper: FunctionComponent<BoxOwnProps & Props> = (props: Props) => {
  const {
    columns = [1, 1, 1, 3],
    eyebrow,
    linkDocumentTypeIndex,
    linkLabel,
    nodes,
    references,
    rowGap = 7,
    small,
    sx,
    title,
    ...rest
  } = props

  const gridTemplateColumns = columns.map(numCols => `repeat(${numCols}, 1fr)`)

  return (
    <Flex
      sx={{
        alignItems: 'center',
        flexDirection: 'column',
        width: '100%',
        ...sx,
      }}
      {...rest}
    >
      {/* Eyebrow */}
      {eyebrow && <Eyebrow mb={6}>{eyebrow}</Eyebrow>}

      {/* Title */}
      {title && (
        <Box
          mb={6}
          sx={{
            fontSize: ['l', null, null, 'xxl'],
            fontWeight: 'semibold',
            lineHeight: 'single',
          }}
        >
          {title}
        </Box>
      )}

      {/* Tags */}
      {linkDocumentTypeIndex && references && (
        <Box mb={12}>
          <Tags large references={references} />
        </Box>
      )}

      <Grid
        sx={{
          columnGap: 6,
          gridTemplateColumns,
          rowGap,
          width: '100%',
        }}
      >
        {nodes && nodes.map(node => node)}
      </Grid>

      {linkDocumentTypeIndex && (
        <Box mt={[10, null, null, 16]}>
          <NextLink href={getSingletonHref(linkDocumentTypeIndex)} passHref>
            <a>
              <Button variant="outline">{linkLabel || 'View more'}</Button>
            </a>
          </NextLink>
        </Box>
      )}
    </Flex>
  )
}

export default GridWrapper
