import { useEmblaCarousel } from 'embla-carousel/react'
import { AnimatePresence } from 'framer-motion'
import React, { ReactNode, useEffect, useState } from 'react'
import { Box, ThemeUIStyleObject } from 'theme-ui'
import useBreakpointIndex from '../../hooks/useBreakpointIndex'
import BoxMotion from '../BoxMotion'
import ButtonIcon from '../ButtonIcon'

type Props = {
  columns?: number[]
  nodes?: ReactNode[]
  sx?: ThemeUIStyleObject
}

const carouselMaskImage = (
  gutterWidth: number,
  showLeft: boolean,
  showRight: boolean
) => {
  return `linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0px,
    rgba(255, 255, 255, 1) ${showLeft ? gutterWidth : 0}px,
    rgba(255, 255, 255, 1) calc(100% - ${showRight ? gutterWidth : 0}px),
    rgba(255, 255, 255, 0) calc(100% - 0px)
  )`
}

const CarouselWrapper = (props: Props) => {
  const { columns = [1, 1, 2], nodes, sx } = props

  const flex = columns.map(numCols => `0 0 ${100 / numCols}%`)

  // State
  const [nextButtonVisible, setNextButtonVisible] = useState(false)
  const [previousButtonVisible, setPreviousButtonVisible] = useState(false)

  const [emblaRef, emblaApi] = useEmblaCarousel({
    align: 'start',
    containScroll: 'trimSnaps',
    draggable: true,
    loop: false,
  })

  const breakpointIndex = useBreakpointIndex()

  // Callbacks
  const handleNext = () => {
    if (!emblaApi) {
      return
    }

    emblaApi.scrollNext()
  }

  const handlePrevious = () => {
    if (!emblaApi) {
      return
    }

    emblaApi.scrollPrev()
  }

  const handleSelected = () => {
    updateEmblaNavigation()
  }

  const updateEmblaNavigation = (
    { reInit }: { reInit?: boolean } = { reInit: false }
  ) => {
    if (!emblaApi) {
      return
    }

    if (reInit) {
      emblaApi.reInit()
    }

    setNextButtonVisible(emblaApi.canScrollNext())
    setPreviousButtonVisible(emblaApi.canScrollPrev())
  }

  // Effects
  useEffect(() => {
    if (emblaApi) {
      emblaApi.on('select', handleSelected)

      // Manually trigger
      handleSelected()
    }

    return () => {
      if (emblaApi) {
        emblaApi.off('select', handleSelected)
      }
    }
  }, [emblaApi])

  useEffect(() => {
    // Re-initialize carousel on breakpoint change and update navigation
    updateEmblaNavigation({ reInit: true })
  }, [breakpointIndex])

  return (
    <Box
      className="embla__viewport"
      ref={emblaRef}
      sx={{
        position: 'relative', //
        maskImage: [
          carouselMaskImage(32, previousButtonVisible, nextButtonVisible),
          null,
          null,
          carouselMaskImage(48, previousButtonVisible, nextButtonVisible),
        ],
        ...sx,
      }}
    >
      <Box
        className="embla__container"
        mx={[2, null, null, 3]}
        sx={{ height: '100%' }}
      >
        {nodes?.map((node, index) => (
          <Box
            className="embla__slide"
            key={index}
            px={[2, null, null, 3]}
            sx={{ flex }}
          >
            {node}
          </Box>
        ))}
      </Box>

      {/* Navigation - previous */}
      <AnimatePresence>
        {/* Button */}
        {previousButtonVisible && (
          <BoxMotion
            // framer-motion
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            initial={{ opacity: 0 }}
            // theme-ui
            onClick={handlePrevious}
            sx={{
              left: 6,
              position: 'absolute',
              top: '50%',
              transform: 'translate(0, -50%)',
            }}
          >
            <ButtonIcon
              background="white"
              borderColor="stone"
              iconSize="12px"
              size="34px"
              type="arrowLeft"
            />
          </BoxMotion>
        )}
      </AnimatePresence>

      {/* Navigation - next */}
      <AnimatePresence>
        {/* Button */}
        {nextButtonVisible && (
          <BoxMotion
            // framer-motion
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            initial={{ opacity: 0 }}
            // theme-ui
            onClick={handleNext}
            sx={{
              right: 6,
              position: 'absolute',
              top: '50%',
              transform: 'translate(0, -50%)',
            }}
          >
            <ButtonIcon
              background="white"
              borderColor="stone"
              iconSize="12px"
              size="34px"
              type="arrowRight"
            />
          </BoxMotion>
        )}
      </AnimatePresence>
    </Box>
  )
}

export default CarouselWrapper
