import { SpeakerPreview } from '@types'
import NextLink from 'next/link'
import React from 'react'
import { useState } from 'react'
import { AspectRatio, Box, Flex } from 'theme-ui'
import ImageAvatar from '../ImageAvatar'
import Link from '../Link'

export type Props = {
  circleIndex?: number
  item: SpeakerPreview
  small?: boolean
}

const SpeakerFigure = (props: Props) => {
  const { circleIndex, item, small } = props

  // State
  const [hover, setHover] = useState(false)
  const speakerHref = item.slug

  return (
    <Flex
      sx={{
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        minHeight: '275px',
        position: 'relative',
        width: '100%',
      }}
    >
      <Flex
        sx={{
          alignItems: 'center',
          flexDirection: 'column',
          flexGrow: 1,
          width: '100%',
        }}
      >
        {/* Image */}
        {item?.image && (
          <Box
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            sx={{
              maxWidth: '300px',
              position: 'relative',
              width: '100%',
            }}
          >
            <NextLink href={speakerHref}>
              <a>
                <AspectRatio ratio={1}>
                  <ImageAvatar
                    circleIndex={circleIndex}
                    image={item.image}
                    sizes="300px"
                    zoomed={hover}
                  />
                </AspectRatio>
              </a>
            </NextLink>
          </Box>
        )}

        <Box mt={3} sx={{ textAlign: 'center', width: '100%' }}>
          {/* Name */}
          {item?.name && (
            <Box my={1}>
              <NextLink href={speakerHref} passHref>
                <Link
                  active={hover}
                  onMouseEnter={() => setHover(true)}
                  onMouseLeave={() => setHover(false)}
                  sx={{
                    fontSize: small ? 'xs' : 's',
                    fontWeight: 'semibold',
                  }}
                  variant="simple"
                >
                  {item.name}
                </Link>
              </NextLink>
            </Box>
          )}

          {/* Role */}
          {item?.role && (
            <Box
              color="slate"
              mt={1}
              mx="auto"
              sx={{
                fontSize: small ? 'xxs' : 'xs',
                lineHeight: 'body',
                maxWidth: '85%',
              }}
            >
              {item.role}
            </Box>
          )}
        </Box>
      </Flex>
    </Flex>
  )
}

export default SpeakerFigure
