// Source: https://github.com/rehooks/document-visibility/blob/master/index.js

let { useState, useEffect } = require('react')

const getVisibility = () => {
  if (typeof document === 'undefined') return true

  // Return true if Page Visibility isn't supported
  if (!('visibilityState' in document)) return true

  return document.visibilityState === 'visible'
  // return document.visibilityState
}

const usePageVisibility = (handler?: (visibility: boolean) => void) => {
  let [documentVisibility, setDocumentVisibility] = useState(getVisibility())

  function handleVisibilityChange() {
    const visibility = getVisibility()
    setDocumentVisibility(visibility)

    if (handler) {
      handler(visibility)
    }
  }

  useEffect(() => {
    document.addEventListener('visibilitychange', handleVisibilityChange)
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [])

  return documentVisibility
}

export default usePageVisibility
