import { FAQ, Testimonial } from '@types'
import React from 'react'
import { Box } from 'theme-ui'
import AccordionList from '../AccordionList'
import ModuleHeading from '../ModuleHeading'
import ModuleWrapper from '../ModuleWrapper'

type ModuleFaqs = {
  _type: 'moduleFaqs'
  faqs: FAQ[]
  heading: ModuleHeading
  testimonials: Testimonial[]
}

export type Props = {
  collapseMargins?: boolean
  module: ModuleFaqs
}

const ModuleFaqs = (props: Props) => {
  const { collapseMargins, module } = props

  return (
    <ModuleWrapper collapseMargins={collapseMargins}>
      <Box
        mx="auto"
        pb={2}
        sx={{
          maxWidth: 'moduleTextOneColMaxWidth',
        }}
      >
        <ModuleHeading heading={module?.heading} />

        {/* Accordion */}
        {module?.faqs && (
          <AccordionList
            sections={module?.faqs?.map(faq => ({
              body: faq.answer,
              title: faq.question,
            }))}
          />
        )}
      </Box>
    </ModuleWrapper>
  )
}

export default ModuleFaqs
