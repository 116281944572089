import { Color } from '@types'
import React from 'react'
import { Box } from 'theme-ui'
import BoxMotion from '../BoxMotion'
import Shape from '../Shape'

type Props = {
  color?: Color
  visible?: boolean
}

const BannerShapes = (props: Props) => {
  const { color, visible } = props

  const transition = {
    damping: 20,
    stiffness: 200,
    type: 'spring',
  }

  return (
    <Box sx={{ pointerEvents: 'none' }}>
      {/* Mobile */}
      <Box sx={{ display: ['block', null, 'none'] }}>
        {/* Top */}
        <BoxMotion
          // framer-motion
          animate={{
            rotate: -180,
            y: visible ? '30%' : '0%',
          }}
          transition={transition}
          // theme-ui
          sx={{
            // border: '1px solid rgba(0, 0, 0, 0.25)',
            bottom: '100%',
            left: '35%',
            width: '35%',
            position: 'absolute',
          }}
        >
          <Shape color={color} patternIndex={4} />
        </BoxMotion>

        {/* BL */}
        <BoxMotion
          // framer-motion
          animate={{
            rotate: -118,
            x: visible ? '70%' : '-5%',
            y: visible ? '-90%' : '-5%',
          }}
          transition={transition}
          // theme-ui
          sx={{
            // border: '1px solid rgba(0, 0, 0, 0.25)',
            height: '25%',
            right: '100%',
            top: '100%',
            width: '25%',
            position: 'absolute',
          }}
        >
          <Shape color={color} patternIndex={13} />
        </BoxMotion>

        {/* BR */}
        <BoxMotion
          // framer-motion
          animate={{
            rotate: -11,
            x: visible ? '-80%' : 0,
            y: visible ? '-60%' : 0,
          }}
          transition={transition}
          // theme-ui
          sx={{
            // border: '1px solid rgba(0, 0, 0, 0.25)',
            height: '35%',
            left: '100%',
            top: '100%',
            width: '35%',
            position: 'absolute',
          }}
        >
          <Shape color={color} patternIndex={8} />
        </BoxMotion>
      </Box>

      {/* Desktop */}
      <Box sx={{ display: ['none', null, 'block'] }}>
        {/* Bottom */}
        <BoxMotion
          // framer-motion
          animate={{
            rotate: -149,
            y: visible ? '-35%' : '5%',
          }}
          transition={transition}
          // theme-ui
          sx={{
            // border: '1px solid rgba(0, 0, 0, 0.25)',
            left: '25%',
            top: '100%',
            width: '25%',
            position: 'absolute',
          }}
        >
          <Shape color={color} patternIndex={5} />
        </BoxMotion>

        {/* Right */}
        <BoxMotion
          // framer-motion
          animate={{
            rotate: -59,
            x: visible ? '-60%' : 0,
          }}
          transition={transition}
          // theme-ui
          sx={{
            // border: '1px solid rgba(0, 0, 0, 0.25)',
            height: '100%',
            left: '100%',
            top: 0,
            width: '35%',
            position: 'absolute',
          }}
        >
          <Shape color={color} patternIndex={8} />
        </BoxMotion>

        {/* Top */}
        <BoxMotion
          // framer-motion
          animate={{
            rotate: -180,
            y: visible ? '20%' : '0%',
          }}
          transition={transition}
          // theme-ui
          sx={{
            // border: '1px solid rgba(0, 0, 0, 0.25)',
            bottom: '100%',
            left: '25%',
            width: '25%',
            position: 'absolute',
          }}
        >
          <Shape color={color} patternIndex={4} />
        </BoxMotion>

        {/* Left */}
        <BoxMotion
          // framer-motion
          animate={{
            rotate: -118,
            x: visible ? '40%' : '-5%',
          }}
          transition={transition}
          // theme-ui
          sx={{
            // border: '1px solid rgba(0, 0, 0, 0.25)',
            bottom: 0,
            height: '100%',
            right: '100%',
            width: '25%',
            position: 'absolute',
          }}
        >
          <Shape color={color} patternIndex={13} />
        </BoxMotion>
      </Box>
    </Box>
  )
}

export default BannerShapes
