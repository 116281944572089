import { SanityImage } from '@types'
import React from 'react'
import { Grid } from 'theme-ui'
import IndexSubheader from '../IndexSubheader'
import LinkCard from '../LinkCard'
import ModuleWrapper from '../ModuleWrapper'

type ModuleCardLinks = {
  _type: 'moduleCardLinks'
  links: {
    description?: string
    image: SanityImage
    title: string
    url: string
  }[]
  title: string
}

export type Props = {
  collapseMargins?: boolean
  module: ModuleCardLinks
}

const ModuleCardLinks = (props: Props) => {
  const { collapseMargins, module } = props

  const gridColumns = [1, 1, 1, 3]
  const gridTemplateColumns = gridColumns.map(
    numCols => `repeat(${numCols}, 1fr)`
  )

  return (
    <ModuleWrapper collapseMargins={collapseMargins}>
      {module?.title && <IndexSubheader large title={module.title} />}

      <Grid
        sx={{
          columnGap: 6,
          gridTemplateColumns,
          rowGap: 7,
          width: '100%',
        }}
      >
        {module?.links?.map((link, index) => (
          <LinkCard item={link} key={index} />
        ))}
      </Grid>
    </ModuleWrapper>
  )
}

export default ModuleCardLinks
