import {
  ArticlePreview,
  EventPreview,
  ManifestoPreview,
  OnlineCoursePreview,
  PlaylistPreview,
  ProductPreview,
  ReadingListPreview,
  TalkPreview,
  WorkshopPreview,
} from '@types'

const isReadableGuard = (
  item:
    | ArticlePreview
    | EventPreview
    | ManifestoPreview
    | OnlineCoursePreview
    | PlaylistPreview
    | ProductPreview
    | ReadingListPreview
    | TalkPreview
    | WorkshopPreview
): item is ArticlePreview | ManifestoPreview | ReadingListPreview => {
  return ['article', 'manifesto', 'readingList'].includes(item._type)
}

export default isReadableGuard
