import {
  ArticlePreview,
  ColorTheme,
  ManifestoPreview,
  OnlineCoursePreview,
  ProductPreview,
  ReadingListPreview,
  TalkPreview,
  WorkshopPreview,
} from '@types'
import NextLink from 'next/link'
import React, { useState } from 'react'
import { AspectRatio, Box, Flex } from 'theme-ui'
import { CurrencyCode } from '../../types/codegen/shopify'
import isCourseGuard from '../../utils/isCourseGuard'
import isOnlineCourseOrWorkshopGuard from '../../utils/isOnlineCourseOrWorkshopGuard'
import isShoppableGuard from '../../utils/isShoppableGuard'
import isTalkGuard from '../../utils/isTalkGuard'
import truncateText from '../../utils/truncateText'
import Button from '../Button'
import ButtonProductContextual from '../ButtonProductContextual'
import CardDetails from '../CardDetails'
import Link from '../Link'
import NextImageZoomable from '../NextImageZoomable'
import ProductPresentmentPriceRange from '../ProductPresentmentPriceRange'
import ReferencesPrimary from '../ReferencesPrimary'
import ReferencesSecondary from '../ReferencesSecondary'
import ReferencesTags from '../ReferencesTags'

export type Props = {
  colorTheme?: ColorTheme
  item:
  | ArticlePreview
  | ManifestoPreview
  | OnlineCoursePreview
  | ProductPreview
  | ReadingListPreview
  | TalkPreview
  | WorkshopPreview
}

const TALK_EXCERPT_LENGTH = 250

const CardLarge = (props: Props) => {
  const { colorTheme, item } = props

  // State
  const [hover, setHover] = useState(false)

  const href = item.slug

  const isProduct = item._type === 'product'

  let anyVariantAvailable
  if (isShoppableGuard(item)) {
    const productVariants = item?.shopify?.variants || []
    anyVariantAvailable = productVariants.reduce((acc, val) => {
      if (val.inStock) {
        acc = true
      }
      return acc
    }, false as boolean)
  }

  let ButtonPrimary = (
    <NextLink href={href}>
      <a>
        <Button
          background={colorTheme?.text}
          color={colorTheme?.background}
          variant="outline"
        >
          {item._type === 'talk' ? 'Watch now' : 'Read now'}
        </Button>
      </a>
    </NextLink>
  )

  if (isShoppableGuard(item)) {
    ButtonPrimary = (
      <ButtonProductContextual
        background={colorTheme?.text}
        color={colorTheme?.background}
        productTitle={item.title}
        product={item.shopify}
        // if there's a color theme, respect it,
        // else just block it to match header
        variant={colorTheme ? 'outline' : undefined}
      />
    )
  }
  if (
    (item._type === 'onlineCourse' || item._type === 'workshop') &&
    item.release?.state === 'subscribe' &&
    item.release.mailingList
  ) {
    ButtonPrimary = (
      <Button
        onClick={() => window.scrollTo({ behavior: 'smooth', top: 0 })}
        background={colorTheme?.text}
        color={colorTheme?.background}
        variant={colorTheme ? 'outline' : undefined}
      >
        {item.release?.mailingList.label || 'Subscribe'}
      </Button>
    )
  }

  return (
    <Flex
      color={colorTheme?.text}
      sx={{
        flexDirection: ['column', null, null, 'row'],
        position: 'relative',
        width: '100%',
      }}
    >
      {/* Image */}
      <Box
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        sx={{
          borderRadius: '2px', // TODO: use theme value
          overflow: 'hidden',
          width: ['100%', null, null, '66%'],
        }}
      >
        <NextLink href={href}>
          <a>
            <AspectRatio bg="lightGray" ratio={423 / 239}>
              {item?.image && (
                <Flex
                  sx={{
                    alignItems: 'center',
                    flex: 1,
                    flexDirection: 'column',
                    height: '100%',
                    justifyContent: 'center',
                    position: 'relative',
                  }}
                >
                  <Box
                    sx={{
                      flexShrink: 0,
                      height: isProduct ? '60%' : '100%',
                      position: 'relative',
                      width: isProduct ? '80%' : '100%',
                    }}
                  >
                    <NextImageZoomable
                      image={item.image}
                      layout="fill"
                      objectFit={isProduct ? 'contain' : 'cover'}
                      sizes={['100vw', null, null, '60vw']}
                      zoomed={hover}
                    />
                  </Box>

                  {/* Card details */}
                  <Box
                    sx={{
                      bottom: 0,
                      display: ['block', null, null, 'none'],
                      left: 0,
                      position: 'absolute',
                      width: '100%',
                    }}
                  >
                    <CardDetails item={item} />
                  </Box>
                </Flex>
              )}
            </AspectRatio>
          </a>
        </NextLink>
      </Box>

      {/* Info */}
      <Box
        ml={[0, null, null, 6]}
        sx={{
          width: ['100%', null, null, '420px'],
        }}
      >
        <Flex
          mt={[3, null, null, 0]}
          sx={{
            flexDirection: ['row', null, null, 'column'],
            justifyContent: ['space-between', null, null, 'flex-start'],
          }}
        >
          {/* Online course / workshop / event */}
          {isCourseGuard(item) && (
            <Box sx={{ display: ['none', null, null, 'block'] }}>
              <ReferencesSecondary
                colorTheme={colorTheme}
                item={item}
                tone={colorTheme ? 'colorTheme' : 'dark'}
              />
            </Box>
          )}

          {/* Title */}
          {item?.title && (
            <Box
              mt={[0, null, null, 3]}
              sx={{
                fontSize: ['m', null, null, 'xl'],
                fontWeight: 'semibold',
              }}
            >
              <NextLink href={href} passHref>
                <Link
                  active={hover}
                  onMouseEnter={() => setHover(true)}
                  onMouseLeave={() => setHover(false)}
                  variant="simple"
                >
                  {item.title}
                </Link>
              </NextLink>
            </Box>
          )}
          {/* Price */}
          {isShoppableGuard(item) && item.shopify && anyVariantAvailable && (
            <Flex
              ml={[4, null, null, 0]}
              mt={[0, null, null, 2]}
              sx={{
                flexShrink: 0,
                fontSize: ['m', null, null, 'l'],
                fontWeight: 'semibold',
              }}
            >
              <ProductPresentmentPriceRange
                compareAtPriceRange={{
                  maxVariantPrice: {
                    amount: item.shopify?.compareAtPriceRange?.maxVariantPrice,
                    currencyCode: 'GBP' as CurrencyCode,
                  },
                  minVariantPrice: {
                    amount: item.shopify?.compareAtPriceRange?.minVariantPrice,
                    currencyCode: 'GBP' as CurrencyCode,
                  },
                }}
                displaySingleVariantComparePrice
                priceRange={{
                  maxVariantPrice: {
                    amount: item.shopify?.priceRange?.maxVariantPrice,
                    currencyCode: 'GBP' as CurrencyCode,
                  },
                  minVariantPrice: {
                    amount: item.shopify?.priceRange?.minVariantPrice,
                    currencyCode: 'GBP' as CurrencyCode,
                  },
                }}
              />
            </Flex>
          )}
        </Flex>

        {/* Primary card references (including speaker) */}
        <Box
          mt={2}
          sx={{
            fontSize: ['xs', null, null, 'm'],
            fontWeight: ['medium', null, null, 'semibold'],
            lineHeight: 'body',
            opacity: 0.8,
          }}
        >
          <ReferencesPrimary item={item} />
        </Box>

        {/* Course: Description */}
        {isOnlineCourseOrWorkshopGuard(item) && item?.description && (
          <Box
            my={5}
            sx={{
              display: ['none', null, null, 'block'],
              fontSize: 's',
              lineHeight: 'body',
            }}
          >
            {item.description}
          </Box>
        )}

        {/* Talk: Excerpt */}
        {isTalkGuard(item) && item?.excerpt && (
          <Box
            my={5}
            sx={{
              display: ['none', null, null, 'block'],
              fontSize: 's',
              lineHeight: 'body',
            }}
          >
            {truncateText(item.excerpt, TALK_EXCERPT_LENGTH)}
          </Box>
        )}

        {/* Tag references */}
        <Box mt={[3, null, null, 5]}>
          <ReferencesTags
            item={item}
            tone={colorTheme?.background ? 'light' : 'blue'}
          />
        </Box>

        <Box mt={5}>
          {/* Add to bag button */}
          {ButtonPrimary}
        </Box>
      </Box>
    </Flex>
  )
}

export default CardLarge
