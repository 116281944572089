import { ItineraryGroup } from '@types'
import React from 'react'
import { Box, Flex } from 'theme-ui'
import AccordionList from '../AccordionList'
import Icon from '../Icon'
import ModuleHeading from '../ModuleHeading'
import ModuleWrapper from '../ModuleWrapper'

type ModuleItinerary = {
  _type: 'moduleItinerary'
  itinerary: ItineraryGroup[]
  heading: ModuleHeading
}

export type Props = {
  collapseMargins?: boolean
  module: ModuleItinerary
}

const ModuleItinerary = (props: Props) => {
  const { collapseMargins, module } = props

  return (
    <ModuleWrapper collapseMargins={collapseMargins}>
      <Box
        mx="auto"
        pb={2}
        sx={{
          maxWidth: 'moduleTextOneColMaxWidth',
        }}
      >
        <ModuleHeading heading={module?.heading} />

        {/* Itinerary */}
        {module?.itinerary && (
          <AccordionList
            sections={module.itinerary?.map(group => ({
              list: group.activities?.map(activity => {
                // activity.label
                return (
                  <Flex sx={{ alignItems: 'center' }}>
                    <Box sx={{ height: '14px', width: '14px' }}>
                      <Icon type="clock" />
                    </Box>
                    <Box ml={1} sx={{ minWidth: '11ch' }}>
                      {activity.timeStart} - {activity.timeEnd}
                    </Box>
                    <Box>{activity.label}</Box>
                  </Flex>
                )
              }),
              title: group.title,
            }))}
          />
        )}
      </Box>
    </ModuleWrapper>
  )
}

export default ModuleItinerary
