import { ButtonVariant, Color, ShopifyProduct } from '@types'
import React from 'react'
import { useDispatch } from 'react-redux'
import { encode } from 'shopify-gid'
import { ThemeUIStyleObject } from 'theme-ui'
import useTypedSelector from '../../hooks/useTypedSelector'
import { systemActions } from '../../redux/modules/system'
import Button from '../Button'

type Props = {
  background?: Color
  color?: Color
  product: ShopifyProduct
  productTitle: string
  singlePurchase?: boolean
  sx?: ThemeUIStyleObject
  variant?: ButtonVariant
}

const ButtonDisplayVariantPanel = (props: Props) => {
  const {
    background,
    color,
    product,
    productTitle,
    singlePurchase,
    sx,
    variant,
  } = props

  // Redux
  const dispatch = useDispatch()
  const lineItems = useTypedSelector(state => state.store.checkout.lineItems)
  const lineItemVariantIds = lineItems?.edges?.map(
    lineItem => lineItem?.node?.variant?.id
  )

  const productVariantIds = product?.variants?.map(variant =>
    encode('ProductVariant', variant?.id)
  )

  const anyVariantInCheckout =
    (lineItemVariantIds?.filter(id => productVariantIds?.includes(id)) || [])
      .length > 0

  // If this is a single purchasable product, determine if all our cart contains at least one variant
  const allowVariantSelection = !(singlePurchase && anyVariantInCheckout)

  // Callbacks
  const handleClick = () => {
    if (allowVariantSelection) {
      dispatch(
        systemActions.showProductVariantPanel({
          product,
          singlePurchase,
          title: productTitle,
        })
      )
    } else {
      dispatch(systemActions.setCartVisible({ cartVisible: true }))
    }
  }

  const options = product?.options
    ?.map(option => option.name.toLowerCase())
    .join(' & ')

  return (
    <Button
      background={background}
      color={color}
      onClick={handleClick}
      sx={sx}
      variant={variant}
    >
      {allowVariantSelection ? `Select ${options}` : 'In your bag'}
    </Button>
  )
}

export default ButtonDisplayVariantPanel
