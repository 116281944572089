import { SanityImage } from '@types'
import React, { useState } from 'react'
import { AspectRatio, Box } from 'theme-ui'
import Link from '../Link'
import NextImage from '../NextImage'

type Props = {
  item: {
    description?: string
    image: SanityImage
    title: string
    url: string
  }
}

const LinkCard = (props: Props) => {
  const { item } = props

  // State
  const [hover, setHover] = useState(false)

  return (
    <Box>
      {/* Image */}
      <a href={item.url}>
        <AspectRatio
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          ratio={423 / 238}
          sx={{
            borderRadius: '2px',
            overflow: 'hidden',
          }}
        >
          <NextImage
            image={item.image}
            layout="fill"
            sizes={['100vw', null, null, '30vw']}
          />
        </AspectRatio>
      </a>

      {item?.title && (
        <Box
          mt={3}
          sx={{
            fontSize: 'm',
            fontWeight: 'semibold',
          }}
        >
          <Link active={hover} href={item.url} variant="simple">
            {item.title}
          </Link>
        </Box>
      )}

      {item?.description && (
        <Box
          mt={2}
          sx={{ fontSize: ['xs', null, null, 's'], lineHeight: 'body' }}
        >
          {item.description}
        </Box>
      )}
    </Box>
  )
}

export default LinkCard
